import { staffProfileReducer } from '@aa/angular/core';
import { customerProfileReducer } from '@aa/angular/core';
import { orderCardAttributeTypeReducer } from '@aa/angular/core';
import { orderCardAttributeReducer } from '@aa/angular/core';
import { orderCardReducer } from '@aa/angular/core';
import { orderStatusReducer } from '@aa/angular/core';
import { orderSourceReducer } from '@aa/angular/core';
import { orderServiceLinkageReducer } from '@aa/angular/core';
import { orderReducer } from '@aa/angular/core';
import { offeredServiceReducer } from '@aa/angular/core';
import { orderUpdateRequestReducer } from '@aa/angular/core';
import { refreshTokenReducer } from '@aa/angular/core';
import { userRoleAssignmentReducer } from '@aa/angular/core';
import { actionLogReducer } from '@aa/angular/core';
import { ActionReducer, createReducer, INIT, MetaReducer } from '@ngrx/store';
import {
  authReducer,
  CoreAppState,
  sidenavIsOpenReducer,
  userReducer,
  userRoleReducer,
} from '@aa/angular/core';
import { invoiceItemReducer } from '@aa/angular/core';
import { invoicePaymentReducer } from '@aa/angular/core';
import { invoiceReducer } from '@aa/angular/core';
import { orderCardServiceLinkageReducer } from '@aa/angular/core';
import { refundItemReducer } from '@aa/angular/core';

export interface StaffAppState extends CoreAppState {}

function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    console.log(`[ACTION]${action.type}`, action, state);

    return reducer(state, action);
  };
}

const hydrationMetaReducer = (
  reducer: ActionReducer<StaffAppState>,
): ActionReducer<StaffAppState> => {
  return (state, action) => {
    if (action.type === INIT) {
      const storageValue = localStorage.getItem('state');
      if (storageValue) {
        try {
          return JSON.parse(storageValue) as StaffAppState;
        } catch {
          localStorage.removeItem('state');
        }
      }
    }
    const nextState = reducer(state, action);
    const copyOfState = {
      auth: nextState?.['auth'],
    };
    localStorage.setItem('state', JSON.stringify(copyOfState));
    return nextState;
  };
};

export const staffAppMetaReducers: MetaReducer[] = [
  debug,
  hydrationMetaReducer,
];

export const staffAppReducers = {
  isStaffApp: createReducer(true),
  sidenavIsOpen: sidenavIsOpenReducer,
  auth: authReducer,
  order: orderReducer,
  orderCard: orderCardReducer,
  user: userReducer,
  userRole: userRoleReducer,
  userRoleAssignment: userRoleAssignmentReducer,
  refreshToken: refreshTokenReducer,
  actionLog: actionLogReducer,
  offeredService: offeredServiceReducer,
  orderServiceLinkage: orderServiceLinkageReducer,
  orderSource: orderSourceReducer,
  orderStatus: orderStatusReducer,
  orderCardAttribute: orderCardAttributeReducer,
  orderCardAttributeType: orderCardAttributeTypeReducer,
  customerProfile: customerProfileReducer,
  staffProfile: staffProfileReducer,
  orderCardServiceLinkage: orderCardServiceLinkageReducer,
  invoice: invoiceReducer,
  invoiceItem: invoiceItemReducer,
  invoicePayment: invoicePaymentReducer,
  refundItem: refundItemReducer,
  orderUpdateRequest: orderUpdateRequestReducer,
};
