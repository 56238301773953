import { CoreAppState } from '../state/core-app.state';
import { BaseResourceService } from './base-resource.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrderUpdateRequest } from '@prisma/client';
import { OrderUpdateRequestResourceTypeMappings } from '@aa/nest/resource';
import { orderUpdateRequestCompositeKeyOrder } from '@aa/nest/resource/objects';

@Injectable()
export class OrderUpdateRequestService extends BaseResourceService<OrderUpdateRequestResourceTypeMappings> {
  constructor(
    protected override readonly http: HttpClient,
    protected override readonly store: Store<CoreAppState>,
  ) {
    super(http, store, 'order-update-requests');
  }
}
