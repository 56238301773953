import { createAction, props } from '@ngrx/store';
import { createBaseResourceActions } from '../create-base-resource-actions';
import {
  InvoiceItemResourceTypeMappings,
  OrderCardServiceLinkageResourceTypeMappings,
  OrderServiceLinkageResourceTypeMappings,
} from '@aa/nest/resource';

// @aa:gen-ignore

const domainSlug = '[INVOICE ITEM]';
export const invoiceItemActions = {
  ...createBaseResourceActions<InvoiceItemResourceTypeMappings>(domainSlug),
  createItem: createAction(
    `${domainSlug} Create Item`,
    props<{
      data: InvoiceItemResourceTypeMappings['createDTO'];
      orderServiceLinkageId?: OrderServiceLinkageResourceTypeMappings['resourcePrimaryKeyT'];
      orderCardServiceLinkageId?: OrderCardServiceLinkageResourceTypeMappings['resourcePrimaryKeyT'];
      andLoadToCurrent?: boolean;
      loadToCurrentInclude?: InvoiceItemResourceTypeMappings['includeT'];
    }>(),
  ),
  updateItem: createAction(
    `${domainSlug} Update Item`,
    props<{
      id: InvoiceItemResourceTypeMappings['resourcePrimaryKeyT'];
      data: InvoiceItemResourceTypeMappings['updateDTO'];
      orderServiceLinkageId?: OrderServiceLinkageResourceTypeMappings['resourcePrimaryKeyT'];
      orderCardServiceLinkageId?: OrderCardServiceLinkageResourceTypeMappings['resourcePrimaryKeyT'];
    }>(),
  ),
};
