import { createAction, props } from '@ngrx/store';
import { createBaseResourceActions } from '../create-base-resource-actions';
import { InvoicePaymentResourceTypeMappings } from '@aa/nest/resource';

// @aa:gen-ignore

const domainSlug = '[INVOICE PAYMENT]';
export const invoicePaymentActions = {
  ...createBaseResourceActions<InvoicePaymentResourceTypeMappings>(domainSlug),

  finalize: createAction(
    `${domainSlug} Finalize`,
    props<{
      id: InvoicePaymentResourceTypeMappings['resourcePrimaryKeyT'];
    }>(),
  ),
  cancel: createAction(
    `${domainSlug} Cancel`,
    props<{
      id: InvoicePaymentResourceTypeMappings['resourcePrimaryKeyT'];
    }>(),
  ),
};
