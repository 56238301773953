import { createAction, props } from '@ngrx/store';
import { createBaseResourceActions } from '../create-base-resource-actions';
import {
  InvoiceResourceTypeMappings,
  OrderResourceTypeMappings,
} from '@aa/nest/resource';

// @aa:gen-ignore

const domainSlug = '[INVOICE]';
export const invoiceActions = {
  ...createBaseResourceActions<InvoiceResourceTypeMappings>(domainSlug),
  autoAdjustForOrder: createAction(
    `${domainSlug} Auto Adjust for Order`,
    props<{
      id: OrderResourceTypeMappings['resourcePrimaryKeyT'];
    }>(),
  ),
  cancelAllForOrder: createAction(
    `${domainSlug} Cancel All For Order`,
    props<{
      id: OrderResourceTypeMappings['resourcePrimaryKeyT'];
    }>(),
  ),
  finalizeAllForOrder: createAction(
    `${domainSlug} Finalize All For Order`,
    props<{
      id: OrderResourceTypeMappings['resourcePrimaryKeyT'];
    }>(),
  ),
  finalize: createAction(
    `${domainSlug} Finalize`,
    props<{
      id: InvoiceResourceTypeMappings['resourcePrimaryKeyT'];
    }>(),
  ),
  cancel: createAction(
    `${domainSlug} Cancel`,
    props<{
      id: InvoiceResourceTypeMappings['resourcePrimaryKeyT'];
    }>(),
  ),
};
