import { ActivatedRoute } from '@angular/router';
import {
  BehaviorSubject,
  distinctUntilChanged,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
} from 'rxjs';
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, OnInit } from '@angular/core';
import {
  CoreAppState,
  InvoiceComponent,
  PaymentService,
} from '@aa/angular/core';
import { InvoiceResourceTypeMappings } from '@aa/nest/resource';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import { MatButtonModule } from '@angular/material/button';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ViewBarComponent } from '../../components/view-bar/view-bar.component';

@Component({
  selector: 'app-checkout-view',
  standalone: true,
  imports: [CommonModule, MatButtonModule, ViewBarComponent, InvoiceComponent],
  templateUrl: './checkout-view.component.html',
  styleUrls: ['./checkout-view.component.scss'],
})
export class CheckoutViewComponent implements OnInit {
  orderId$: Observable<number>;

  invoiceAndPaymentDetails$ = new BehaviorSubject<{
    invoice: InvoiceResourceTypeMappings['resourceWithRelationsT'];
    paymentIntent: { clientSecret: string };
    customerSession: { clientSecret: string };
  } | null>(null);

  stripe?: Stripe | null;
  elements?: StripeElements | null;

  constructor(
    private readonly store: Store<CoreAppState>,
    private route: ActivatedRoute,
    private readonly paymentService: PaymentService,
    private readonly destroyRef: DestroyRef,
  ) {
    this.orderId$ = this.route.paramMap.pipe(
      map((paramMap) => parseInt(paramMap.get('draftId')!)),
      distinctUntilChanged(),
      takeUntilDestroyed(),
    );

    this.orderId$
      .pipe(takeUntilDestroyed(destroyRef))
      .subscribe(async (orderId) => {
        if (orderId) {
          const res = await lastValueFrom(
            this.paymentService.getInvoiceAndPaymentDetailsForNewOrder(orderId),
          );
          if (res) this.invoiceAndPaymentDetails$.next(res);
        }
      });

    this.invoiceAndPaymentDetails$.subscribe(async (res) => {
      if (!res) return;

      if (!this.stripe) {
        this.stripe = await loadStripe(
          'pk_test_51Ps6hvRq5jAgFm8fdEPXx02bSakqpr8GQpSMenRaVCn1otX5wOhFlDoJwV3Kwlfcwv1maaWDXviFuhLUkXJrk47v002JZ35gi7',
        );
      }

      console.log('TEST', res);

      this.elements = this.stripe!.elements({
        appearance: {
          theme: 'stripe',
        },
        customerSessionClientSecret: res.customerSession.clientSecret,
        clientSecret: res.paymentIntent.clientSecret,
      });

      const paymentElement = this.elements.create('payment', {
        layout: 'tabs',
      });
      paymentElement.mount('#payment-element');
    });
  }

  ngOnInit() {}

  async submit(event: Event) {
    event.preventDefault();
    if (!this.stripe || !this.elements) return;
    const { error } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        return_url: `${window.location.origin}/order/${await firstValueFrom(
          this.orderId$,
        )}/payment?success=true`,
      },
    });

    if (error) {
      console.log('Failed to Process Payment', error);
      window.location.href = `${
        window.location.origin
      }/order/${await firstValueFrom(this.orderId$)}/payment`;
    }
  }
}
