import { BaseResourceTypeMappings } from '@aa/nest/common';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Action, ActionCreator, Store } from '@ngrx/store';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { BehaviorSubject, map } from 'rxjs';
import { CoreAppState } from '../../state/core-app.state';
import { BaseResourceState } from '../../state/create-base-resource-reducer';

export interface FormModalData<
  FormModelT,
  InputModelT = FormModelT,
  MetaT = any,
> {
  model?: InputModelT;
  dontStripKeys?: string[];
  mode: 'create' | 'update' | 'custom';
  onSubmit?: (model: FormModelT) => any;
  hide?: string[];
  meta?: MetaT;
}

export interface BaseResourceFormModalOptionsMapper<
  ResourceTypeMappings extends
    BaseResourceTypeMappings<any> = BaseResourceTypeMappings<any>,
> {
  getLabel?: (item: ResourceTypeMappings['resourceT']) => string;
  loadAction?: Action;
  stateSelector: (
    state: CoreAppState,
  ) => BaseResourceState<ResourceTypeMappings>;
}

export interface BaseResourceFormModelOptionsMappers {
  [key: string]: BaseResourceFormModalOptionsMapper<any>;
}

export interface LabeledOption {
  label: string;
  value: any;
}

export const baseFormModalImports = [
  CommonModule,
  MatButtonModule,
  ReactiveFormsModule,
  FormlyModule,
  FormlyMaterialModule,
  FormlyMatToggleModule,
  MatSlideToggleModule,
  MatProgressSpinnerModule,
];

@Component({
  selector: 'aa-base-form-modal',
  standalone: true,
  imports: [baseFormModalImports],
  templateUrl: './base-form-modal.component.html',
  styleUrl: './base-form-modal.component.scss',
})
export abstract class BaseFormModalComponent<
  FormModelT extends object,
  FormModalDataT extends FormModalData<any> = FormModalData<FormModelT>,
  CreateModelT = FormModelT,
  UpdateModelT = any,
> implements OnInit
{
  abstract title: string;
  model: FormModelT = {} as FormModelT;
  form = new FormGroup({});
  abstract fields: FormlyFieldConfig[];

  extraCreateActionData: any = {};
  extraUpdateActionData: any = {};

  loading$ = new BehaviorSubject(true);

  primaryKeyField = 'id';
  abstract createAction?: ActionCreator<any, (props: any) => any>;
  abstract updateAction?: ActionCreator<any, (props: any) => any>;

  optionsMappers: BaseResourceFormModelOptionsMappers = {};
  dontStripKeys: (keyof FormModelT)[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected readonly data: FormModalDataT,
    protected readonly dialogRef: MatDialogRef<
      BaseFormModalComponent<
        FormModelT,
        FormModalDataT,
        CreateModelT,
        UpdateModelT
      >
    >,
    protected readonly store: Store<any>,
  ) {}

  async ngOnInit() {
    if (this.data.hide) {
      this.fields = [
        ...this.fields.map((f) =>
          f.key && this.data.hide?.includes(`${f.key}`)
            ? { ...f, hide: true }
            : f,
        ),
      ];
    }

    await this.setupOptions();
    if (this.data.model) {
      this.model = { ...this.data.model };
    }
    this.loading$.next(false);
  }

  async setupOptions(
    fieldSet: FormlyFieldConfig[] = this.fields,
    path?: number[],
  ) {
    for (const [index, field] of fieldSet.entries()) {
      if (
        field.key &&
        Object.keys(this.optionsMappers).includes(`${field.key}`)
      ) {
        // dispatch load
        const optionsMapper = this.optionsMappers[`${field.key}`];
        if (optionsMapper.loadAction)
          this.store.dispatch(optionsMapper.loadAction);

        let scopedFieldSet = this.fields;
        if (path) {
          for (const index of path) {
            scopedFieldSet =
              ((scopedFieldSet[index].fieldArray as any)?.fieldGroup as any) ??
              scopedFieldSet[index].fieldGroup;
          }
        }
        scopedFieldSet[index].props = {
          ...scopedFieldSet[index].props,
          options: this.store
            .select((s) => optionsMapper.stateSelector(s).items)
            .pipe(
              map((items) =>
                items.map((item: any) => ({
                  label: optionsMapper.getLabel
                    ? optionsMapper.getLabel(item)
                    : item['title'] ?? item['label'] ?? item['name'],
                  value: item.id,
                })),
              ),
              map((items) =>
                !field.props?.required
                  ? [{ label: 'None', value: null }, ...items]
                  : items,
              ),
            ),
        };
      }

      if (field.fieldGroup) {
        this.setupOptions(field.fieldGroup, [...(path ?? []), index]);
      }
      if ((field.fieldArray as any)?.fieldGroup) {
        this.setupOptions((field.fieldArray as any).fieldGroup, [
          ...(path ?? []),
          index,
        ]);
      }
    }
  }

  submit() {
    let stripped = {};
    let model = this.model;

    if (this.mapModelBeforeSubmit) model = this.mapModelBeforeSubmit(model);
    for (const [key, value] of Object.entries(model)) {
      const findField = (
        fields: FormlyFieldConfig[],
        key: string,
      ): FormlyFieldConfig | undefined =>
        fields.find(
          (f) =>
            f.key == key ||
            (f.fieldGroup ? findField(f.fieldGroup, key) : false),
        );
      const field = findField(this.fields, key);
      if (
        (!!field && field.formControl?.dirty) ||
        [...this.dontStripKeys, ...(this.data.dontStripKeys ?? [])]?.includes(
          key,
        )
      )
        stripped = { ...stripped, [key]: value };
    }

    if (this.data.mode == 'create' && this.createAction && this.store) {
      this.store.dispatch(
        this.createAction({
          data: stripped as any as CreateModelT,
          ...(this.extraCreateActionData ?? {}),
        }),
      );
      this.dialogRef.close();
    } else if (this.data.mode == 'update' && this.updateAction && this.store) {
      this.store.dispatch(
        this.updateAction({
          id: (this.model as any)[this.primaryKeyField],
          data: stripped as unknown as UpdateModelT,
          ...(this.extraUpdateActionData ?? {}),
        }),
      );
      this.dialogRef.close();
    } else if (this.data.onSubmit) {
      this.data.onSubmit(stripped);
      this.dialogRef.close();
    } else if (this.onSubmit) {
      this.onSubmit(stripped as any);
      this.dialogRef.close();
    }
  }

  protected onSubmit?: (stripped: FormModelT) => any;
  protected mapModelBeforeSubmit?: (model: FormModelT) => any;
}
