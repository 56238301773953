import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BaseResourceEffects } from '../base-resource.effects';
import { invoicePaymentActions } from './invoice-payment.actions';
import { InvoicePaymentService } from '../../services/invoice-payment.service';
import { CoreAppState } from '../core-app.state';
import { Injectable } from '@angular/core';
import { selectInvoicePaymentState } from './invoice-payment.reducer';
import { Store } from '@ngrx/store';
import { InvoicePaymentResourceTypeMappings } from '@aa/nest/resource';
import { mergeMap, take, switchMap, map, catchError, of } from 'rxjs';
import { PaymentService } from '../../services/payment.service';

// @aa:gen-ignore

@Injectable()
export class InvoicePaymentEffects extends BaseResourceEffects<InvoicePaymentResourceTypeMappings> {
  finalize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicePaymentActions.finalize),
      mergeMap((payload) =>
        this.store
          .select((s) => this.stateSelector(s).currentQuery)
          .pipe(
            take(1),
            switchMap((currentQuery) =>
              this.paymentService.finalizeInvoicePayment(payload.id).pipe(
                map((res) =>
                  this.itemActions.loadItems({
                    query: currentQuery as any,
                  }),
                ),
                catchError((err) =>
                  of(
                    this.itemActions.setError({
                      error:
                        err.error?.message?.toString() ??
                        err.message?.toString() ??
                        err.toString(),
                    }),
                  ),
                ),
              ),
            ),
          ),
      ),
    ),
  );

  cancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicePaymentActions.cancel),
      mergeMap((payload) =>
        this.store
          .select((s) => this.stateSelector(s).currentQuery)
          .pipe(
            take(1),
            switchMap((currentQuery) =>
              this.paymentService.cancelInvoicePayment(payload.id).pipe(
                map((res) =>
                  this.itemActions.loadItems({
                    query: currentQuery as any,
                  }),
                ),
                catchError((err) =>
                  of(
                    this.itemActions.setError({
                      error:
                        err.error?.message?.toString() ??
                        err.message?.toString() ??
                        err.toString(),
                    }),
                  ),
                ),
              ),
            ),
          ),
      ),
    ),
  );

  constructor(
    protected override readonly actions$: Actions,
    protected override readonly store: Store<CoreAppState>,
    protected override readonly service: InvoicePaymentService,
    private readonly paymentService: PaymentService,
  ) {
    super(
      actions$,
      store,
      service,
      invoicePaymentActions,
      selectInvoicePaymentState,
    );
  }
}
