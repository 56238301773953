import { Actions } from '@ngrx/effects';
import { BaseResourceEffects } from '../base-resource.effects';
import { orderUpdateRequestActions } from './order-update-request.actions';
import { OrderUpdateRequestService } from '../../services/order-update-request.service';
import { CoreAppState } from '../core-app.state';
import { Injectable } from '@angular/core';
import { selectOrderUpdateRequestState } from './order-update-request.reducer';
import { Store } from '@ngrx/store';
import { OrderUpdateRequestResourceTypeMappings } from '@aa/nest/resource';

@Injectable()
export class OrderUpdateRequestEffects extends BaseResourceEffects<OrderUpdateRequestResourceTypeMappings> {
  constructor(
    protected override readonly actions$: Actions,
    protected override readonly store: Store<CoreAppState>,
    protected override readonly service: OrderUpdateRequestService,
  ) {
    super(
      actions$,
      store,
      service,
      orderUpdateRequestActions,
      selectOrderUpdateRequestState,
    );
  }
}
