import { OrderCardAttribute, Prisma } from '@prisma/client';
import { CreateOrderCardAttributeDTO } from './dto/create-order-card-attribute.dto';
import { UpdateOrderCardAttributeDTO } from './dto/update-order-card-attribute.dto';
import { QueryOrderCardAttributeDTO } from './dto/query-order-card-attribute.dto';

import {
  BaseResourceTypeMappings,
  MapPossibleIncludesToRequiredDepth,
} from '@aa/nest/common';
import { XOR } from '@aa/ts/common';

export type OrderCardAttributeResourceTypeMappings = BaseResourceTypeMappings<
  OrderCardAttribute,
  OrderCardAttribute &
    Exclude<
      Partial<
        Prisma.OrderCardAttributeGetPayload<{
          include: MapPossibleIncludesToRequiredDepth<
            Prisma.OrderCardAttributeInclude,
            4
          >;
        }>
      >,
      OrderCardAttribute
    >,
  {
    orderCardId: number;
    orderCardAttributeTypeId: number;
  },
  XOR<
    Prisma.OrderCardAttributeCreateInput,
    Prisma.OrderCardAttributeUncheckedCreateInput
  >,
  XOR<
    Prisma.OrderCardAttributeUpdateInput,
    Prisma.OrderCardAttributeUncheckedUpdateInput
  >,
  Prisma.OrderCardAttributeWhereInput,
  Prisma.OrderCardAttributeWhereUniqueInput,
  Prisma.OrderCardAttributeInclude,
  Prisma.OrderCardAttributeOrderByWithRelationInput,
  CreateOrderCardAttributeDTO,
  UpdateOrderCardAttributeDTO,
  QueryOrderCardAttributeDTO
>;

export const orderCardAttributeCompositeKeyOrder: (keyof OrderCardAttributeResourceTypeMappings['resourceT'])[] =
  ['orderCardId', 'orderCardAttributeTypeId'];
