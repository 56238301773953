import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BaseResourceEffects } from '../base-resource.effects';
import { refundItemActions } from './refund-item.actions';
import { RefundItemService } from '../../services/refund-item.service';
import { CoreAppState } from '../core-app.state';
import { Injectable } from '@angular/core';
import { selectRefundItemState } from './refund-item.reducer';
import { Store } from '@ngrx/store';
import { RefundItemResourceTypeMappings } from '@aa/nest/resource';
import { PaymentService } from '../../services/payment.service';
import { mergeMap, take, switchMap, map, catchError, of, delay } from 'rxjs';

// @aa:gen-ignore

@Injectable()
export class RefundItemEffects extends BaseResourceEffects<RefundItemResourceTypeMappings> {
  finalize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refundItemActions.finalize),
      mergeMap((payload) =>
        this.store
          .select((s) => this.stateSelector(s).currentQuery)
          .pipe(
            take(1),
            switchMap((currentQuery) =>
              this.paymentService.finalizeRefundItem(payload.id).pipe(
                delay(1000),
                map((res) =>
                  this.itemActions.loadItems({
                    query: currentQuery as any,
                  }),
                ),
                catchError((err) =>
                  of(
                    this.itemActions.setError({
                      error:
                        err.error?.message?.toString() ??
                        err.message?.toString() ??
                        err.toString(),
                    }),
                  ),
                ),
              ),
            ),
          ),
      ),
    ),
  );

  cancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refundItemActions.cancel),
      mergeMap((payload) =>
        this.store
          .select((s) => this.stateSelector(s).currentQuery)
          .pipe(
            take(1),
            switchMap((currentQuery) =>
              this.paymentService.cancelRefundItem(payload.id).pipe(
                delay(1000),
                map((res) =>
                  this.itemActions.loadItems({
                    query: currentQuery as any,
                  }),
                ),
                catchError((err) =>
                  of(
                    this.itemActions.setError({
                      error:
                        err.error?.message?.toString() ??
                        err.message?.toString() ??
                        err.toString(),
                    }),
                  ),
                ),
              ),
            ),
          ),
      ),
    ),
  );
  constructor(
    protected override readonly actions$: Actions,
    protected override readonly store: Store<CoreAppState>,
    protected override readonly service: RefundItemService,
    private readonly paymentService: PaymentService,
  ) {
    super(actions$, store, service, refundItemActions, selectRefundItemState);
  }
}
