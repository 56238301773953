import * as inflection from 'inflection';

export const getActionLogSummaryString = (actionLog: any) => {
  let result = '';
  if (actionLog.user?.staffProfile) {
    result += `Staff Member ${actionLog?.user?.staffProfile?.firstName ?? ''} ${
      actionLog?.user?.staffProfile?.lastName ?? ''
    }`;
  } else if (actionLog.user?.customerProfile) {
    result += `Customer ${actionLog?.user?.customerProfile?.firstName ?? ''} ${
      actionLog?.user?.customerProfile?.lastName ?? ''
    }`;
  }

  if (actionLog.didCreateResource) {
    result += ` created`;
  } else if (actionLog.didUpdateResource) {
    result += ` updated`;
  } else if (actionLog.didDeleteResource) {
    result += ` deleted`;
  } else {
    result += 'did something';
  }

  if (!actionLog.resourceId) {
    result += ` a`;
  }
  result += ` ${inflection.titleize(
    inflection.underscore(actionLog.resourceType ?? 'resource'),
  )}`;
  if (actionLog.resourceId) {
    result += `-${actionLog.resourceId}`;
  }

  if (actionLog.parentResourceId) {
    result += ` (on parent resource of type ${inflection.titleize(
      inflection.underscore(actionLog.parentResourceType ?? 'resource'),
    )} with ID of ${actionLog.parentResourceId})`;
  }

  result += ` with values:  ${Object.entries(
    (actionLog.payload as any)['effects'] ?? {},
  )
    .map(
      ([key, value]) =>
        `${inflection.titleize(inflection.underscore(key))}: ${
          typeof value == 'object'
            ? (value as any).name
              ? (value as any).name
              : JSON.stringify(value)
            : value
        }`,
    )
    .join(', ')}`;

  return result;
};
