import {
  CoreAppState,
  InvoiceHistoryComponent,
  orderActions,
  orderCardActions,
  selectOrderCardState,
  selectOrderState,
} from '@aa/angular/core';
import {
  OrderCardResourceTypeMappings,
  OrderResourceTypeMappings,
} from '@aa/nest/resource';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom, map, Observable } from 'rxjs';
import { ViewBarComponent } from '../../components/view-bar/view-bar.component';
import { ReviewCardsComponent } from '../../components/review-cards/review-cards.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'aa-order-review-view',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    ViewBarComponent,
    ReviewCardsComponent,
    InvoiceHistoryComponent,
  ],
  templateUrl: './order-review-view.component.html',
  styleUrl: './order-review-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderReviewViewComponent {
  orderId$: Observable<number>;
  order$: Observable<
    OrderResourceTypeMappings['resourceWithRelationsT'] | null
  >;

  cards$: Observable<OrderCardResourceTypeMappings['resourceWithRelationsT'][]>;

  constructor(
    private readonly store: Store<CoreAppState>,
    private readonly route: ActivatedRoute,
  ) {
    this.orderId$ = this.route.paramMap.pipe(
      map((paramMap) => parseInt(paramMap.get('orderId')!)),
      takeUntilDestroyed(),
    );
    this.order$ = this.store
      .select((s) => selectOrderState(s).current)
      .pipe(takeUntilDestroyed());
    this.cards$ = this.store
      .select((s) => selectOrderCardState(s).items)
      .pipe(takeUntilDestroyed());

    this.orderId$.subscribe((orderId) => {
      this.store.dispatch(
        orderActions.loadItem({
          id: orderId,
        }),
      );
      this.store.dispatch(
        orderCardActions.loadItems({
          query: {
            where: {
              orderId,
            },
            include: {
              orderCardAttributes: {
                include: {
                  orderCardAttributeType: true,
                },
              },
              orderCardServiceLinkages: {
                include: {
                  offeredService: true,
                },
              },
            },
          },
        }),
      );
    });
  }

  async rejectChanges() {
    const orderId = await firstValueFrom(this.orderId$);
    this.store.dispatch(
      orderActions.setChangeApprovalStatus({
        id: orderId,
        approved: false,
      }),
    );
  }

  async approveChanges() {
    const orderId = await firstValueFrom(this.orderId$);
    this.store.dispatch(
      orderActions.setChangeApprovalStatus({
        id: orderId,
        approved: true,
      }),
    );
  }
}
