<div class="card">
  <div class="count">
    <p>{{ order.orderCards?.length ?? 0 }}</p>
    <p>Cards</p>
  </div>
  <div class="details">
    <p class="order-name">{{ order.name }}</p>
    @if (!isDraft()) {
      <p
        class="order-status"
        [ngStyle]="{
          backgroundColor: order.orderStatus?.hexColor,
          color: computeTextColorForBackground(
            order.orderStatus?.hexColor ?? '#ffffff'
          )
        }">
        {{ order.orderStatus?.name }}
      </p>
    }
    <p>Created {{ formatDate(order.createdAt) }}</p>
    <p>Updated {{ formatDate(order.updatedAt) }}</p>
  </div>
  <div class="actions">
    @if (isDraft()) {
      <button mat-icon-button [routerLink]="'draft-editor/' + order.id">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button (click)="promptDelete()">
        <mat-icon>delete</mat-icon>
      </button>
    } @else {
      <button
        mat-icon-button
        [routerLink]="'order-review/' + order.id"
        class="mr-1">
        <mat-icon iconPositionEnd>receipt_long</mat-icon>
      </button>
    }
  </div>
</div>
