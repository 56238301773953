import { OrderResourceTypeMappings } from '@aa/nest/resource';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrderService } from '../../services/order.service';
import { BaseResourceEffects, NoAction } from '../base-resource.effects';
import { CoreAppState } from '../core-app.state';
import { orderCardActions } from '../order-card/order-card.actions';
import { orderServiceLinkageActions } from '../order-service-linkage/order-service-linkage.actions';
import { orderActions } from './order.actions';
import { selectOrderState } from './order.reducer';
import { catchError, map, of, switchMap } from 'rxjs';

// @aa:gen-ignore

@Injectable()
export class OrderEffects extends BaseResourceEffects<OrderResourceTypeMappings> {
  sendChangeEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(orderActions.sendChangeEmail),
      switchMap((payload) =>
        this.service
          .sendChangeNotification(payload.id)
          .pipe(
            map((res) => (res ? this.itemActions.refreshItem() : NoAction())),
          ),
      ),
      catchError((err) =>
        of(
          this.itemActions.setError({
            error:
              err.error?.message?.toString() ??
              err.message?.toString() ??
              err.toString(),
          }),
        ),
      ),
    ),
  );

  setChangeApprovalStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(orderActions.setChangeApprovalStatus),
      switchMap((payload) =>
        this.service
          .setChangeApprovalStatus(payload.id, payload.approved)
          .pipe(
            map((res) => (res ? this.itemActions.refreshItem() : NoAction())),
          ),
      ),
      catchError((err) =>
        of(
          this.itemActions.setError({
            error:
              err.error?.message?.toString() ??
              err.message?.toString() ??
              err.toString(),
          }),
        ),
      ),
    ),
  );

  constructor(
    protected override readonly actions$: Actions,
    protected override readonly store: Store<CoreAppState>,
    protected override readonly service: OrderService,
  ) {
    super(actions$, store, service, orderActions, selectOrderState, [
      orderCardActions.setItems,
      orderServiceLinkageActions.setItems,
    ]);
  }
}
