import { orderUpdateRequestActions } from './order-update-request.actions';
import { CoreAppState } from '../core-app.state';
import {
  BaseResourceState,
  createBaseResourceReducer,
  initialResourceState,
} from '../create-base-resource-reducer';
import { OrderUpdateRequestResourceTypeMappings } from '@aa/nest/resource';

export type OrderUpdateRequestState =
  BaseResourceState<OrderUpdateRequestResourceTypeMappings>;
export const initialOrderUpdateRequestState: OrderUpdateRequestState =
  initialResourceState;

export const orderUpdateRequestReducer = createBaseResourceReducer<
  OrderUpdateRequestResourceTypeMappings,
  OrderUpdateRequestState
>(initialOrderUpdateRequestState, orderUpdateRequestActions);

export const selectOrderUpdateRequestState = (state: CoreAppState) =>
  state.orderUpdateRequest ?? initialOrderUpdateRequestState;
