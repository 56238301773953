import { Actions } from '@ngrx/effects';
import { BaseResourceEffects } from '../base-resource.effects';
import { orderCardServiceLinkageActions } from './order-card-service-linkage.actions';
import { OrderCardServiceLinkageService } from '../../services/order-card-service-linkage.service';
import { CoreAppState } from '../core-app.state';
import { Injectable } from '@angular/core';
import { selectOrderCardServiceLinkageState } from './order-card-service-linkage.reducer';
import { Store } from '@ngrx/store';
import { OrderCardServiceLinkageResourceTypeMappings } from '@aa/nest/resource';

@Injectable()
export class OrderCardServiceLinkageEffects extends BaseResourceEffects<OrderCardServiceLinkageResourceTypeMappings> {
  constructor(
    protected override readonly actions$: Actions,
    protected override readonly store: Store<CoreAppState>,
    protected override readonly service: OrderCardServiceLinkageService,
  ) {
    super(
      actions$,
      store,
      service,
      orderCardServiceLinkageActions,
      selectOrderCardServiceLinkageState,
    );
  }
}
