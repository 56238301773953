import {
  PaymentService,
  selectOrderCardState,
  orderActions,
  orderCardActions,
  selectOrderState,
} from '@aa/angular/core';
import {
  OrderCardResourceTypeMappings,
  OrderCardServiceLinkageResourceTypeMappings,
  OrderResourceTypeMappings,
} from '@aa/nest/resource';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, map, of } from 'rxjs';
import { CustomerAppState } from '../../state/index.reducers';
import { ViewBarComponent } from '../view-bar/view-bar.component';
import * as inflection from 'inflection';

type AdjustedServicesSummary = {
  [
    cardId: number
  ]: (OrderCardServiceLinkageResourceTypeMappings['resourceWithRelationsT'] & {
    deleted?: boolean;
    added?: boolean;
  })[];
};

@Component({
  selector: 'aa-review-cards',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    ViewBarComponent,
  ],
  templateUrl: './review-cards.component.html',
  styleUrl: './review-cards.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewCardsComponent {
  @Input() orderId?: number;

  orderId$: Observable<number | undefined>;
  order$: Observable<
    OrderResourceTypeMappings['resourceWithRelationsT'] | null
  >;
  adjustedServicesSummary$: Observable<AdjustedServicesSummary>;
  cards$: Observable<OrderCardResourceTypeMappings['resourceWithRelationsT'][]>;
  backPath$: Observable<string>;

  constructor(
    private readonly store: Store<CustomerAppState>,
    private readonly route: ActivatedRoute,
    private readonly paymentService: PaymentService,
  ) {
    this.cards$ = this.store
      .select((s) => selectOrderCardState(s).items)
      .pipe(takeUntilDestroyed());
    this.adjustedServicesSummary$ = this.cards$.pipe(
      map((cards) => {
        let adjustedServicesSummary: AdjustedServicesSummary = {};

        for (const card of cards) {
          const hasDraftedChanges = !!card.orderCardServiceLinkages?.find(
            (linkage) => linkage.isDraft,
          );
          adjustedServicesSummary[card.id] = [];
          for (const linkage of card.orderCardServiceLinkages ?? []) {
            if (hasDraftedChanges) {
              if (linkage.isDraft) {
                if (
                  !card.orderCardServiceLinkages?.find(
                    (existing) =>
                      existing.offeredServiceId == linkage.offeredServiceId &&
                      !existing.isDraft,
                  )
                ) {
                  adjustedServicesSummary[card.id].push({
                    ...(linkage as any),
                    added: true,
                  });
                } else {
                  adjustedServicesSummary[card.id].push({
                    ...(linkage as any),
                  });
                }
              } else {
                if (
                  !card.orderCardServiceLinkages?.find(
                    (existing) =>
                      existing.offeredServiceId == linkage.offeredServiceId &&
                      existing.isDraft,
                  )
                ) {
                  adjustedServicesSummary[card.id].push({
                    ...(linkage as any),
                    deleted: true,
                  });
                }
              }
            } else {
              adjustedServicesSummary[card.id].push(linkage as any);
            }
          }
          adjustedServicesSummary[card.id] = adjustedServicesSummary[
            card.id
          ].sort((a, b) => a.offeredServiceId - b.offeredServiceId);
        }

        return adjustedServicesSummary;
      }),
    );
    this.order$ = this.store
      .select((s) => selectOrderState(s).current)
      .pipe(takeUntilDestroyed());
    this.orderId$ = this.route.paramMap.pipe(
      map((paramMap) => parseInt(paramMap.get('orderId')!)),
      takeUntilDestroyed(),
    );
    this.backPath$ = this.orderId$.pipe(
      map((draftId) => `/draft-editor/${draftId}`),
    );

    this.orderId$.subscribe((draftId) => {
      if (draftId) {
        this.store.dispatch(
          orderActions.loadItem({
            id: draftId,
            include: {
              orderServiceLinkages: true,
            },
          }),
        );
        this.store.dispatch(
          orderCardActions.loadItems({
            query: {
              where: {
                orderId: draftId,
              },
              include: {
                orderCardAttributes: {
                  include: {
                    orderCardAttributeType: true,
                  },
                },
                orderCardServiceLinkages: {
                  include: {
                    offeredService: true,
                  },
                },
              },
            },
          }),
        );
      }
    });
  }

  ngAfterViewInit() {
    if (this.orderId) this.orderId$ = of(this.orderId);
  }

  displayCardAttributeValue(fieldName: string | undefined, value: string) {
    let attributeValue = value;
    if (
      fieldName == 'pokemonType' &&
      !!attributeValue &&
      attributeValue.includes('[')
    ) {
      attributeValue = JSON.parse(value).join(', ');
    }
    return inflection.titleize(`${attributeValue ?? ''}`.replaceAll('-', ' '));
  }

  isString(value: string) {
    return typeof value == 'string';
  }
}
