import { OrderCardResourceTypeMappings } from '@aa/nest/resource';
import { createAction, props } from '@ngrx/store';
import { createBaseResourceActions } from '../create-base-resource-actions';

// @aa:gen-ignore

const domainSlug = '[ORDER CARD]';
export const orderCardActions = {
  ...createBaseResourceActions<OrderCardResourceTypeMappings>(domainSlug),
  createCardWithAttributes: createAction(
    `${domainSlug} Create Card with Attributes`,
    props<{
      cardData: OrderCardResourceTypeMappings['createDTO'];
      offeredServicesIds?: number[];
      attributes: { fieldName: string; value: any }[];
    }>(),
  ),
  updateCardWithAttributes: createAction(
    `${domainSlug} Update Card with Attributes`,
    props<{
      id: number;
      cardUpdates: OrderCardResourceTypeMappings['updateDTO'];
      offeredServicesIds?: number[];
      draftOfferedServicesIds?: number[];
      attributes: {
        orderCardId?: number;
        orderCardAttributeTypeId?: number;
        fieldName: string;
        value: any;
      }[];
    }>(),
  ),
};
