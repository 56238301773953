import { createAction, props } from '@ngrx/store';
import { createBaseResourceActions } from '../create-base-resource-actions';
import { OrderResourceTypeMappings } from '@aa/nest/resource';

// @aa:gen-ignore

const domainSlug = '[ORDER]';
export const orderActions = {
  ...createBaseResourceActions<OrderResourceTypeMappings>(domainSlug),

  sendChangeEmail: createAction(
    `${domainSlug} Send Change Email`,
    props<{
      id: number;
    }>(),
  ),
  setChangeApprovalStatus: createAction(
    `${domainSlug} Set Change Approval Status`,
    props<{
      id: number;
      approved: boolean;
    }>(),
  ),
};
