import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioButton } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  isObservable,
  map,
  Observable,
  of,
  startWith,
} from 'rxjs';

export interface MultipleRequestedServicesFormFieldValue {
  draftValues: any[];
  values: any[];
  isDraft?: boolean;
}
export interface SingleRequestedServicesFormFieldValue {
  draftValue: any;
  value: any;
  isDraft?: boolean;
}

export type RequestedServicesFormFieldValue =
  | SingleRequestedServicesFormFieldValue
  | MultipleRequestedServicesFormFieldValue;

@Component({
  selector: 'aa-requested-services-form-field',
  standalone: true,
  imports: [
    CommonModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatRadioButton,
  ],
  templateUrl: './requested-services-form-field.component.html',
  styleUrl: './requested-services-form-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestedServicesFormFieldComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  fieldOptions$!: Observable<{ label: string; value: any }[]>;
  isDraft$ = new BehaviorSubject(false);

  value$ = new BehaviorSubject<RequestedServicesFormFieldValue | null>(null);

  ngOnInit() {
    if (isObservable(this.props.options)) {
      this.fieldOptions$ = this.props.options;
    } else {
      this.fieldOptions$ = of(this.props.options as any[]);
    }

    this.formControl.valueChanges
      .pipe(startWith(this.formControl.value))
      .subscribe(async (value) => {
        this.value$.next(value);
      });

    this.isDraft$.subscribe(async (isDraft) => {
      const currentValue = await firstValueFrom(this.value$);
      let additionalAdjustments: Partial<
        | MultipleRequestedServicesFormFieldValue
        | SingleRequestedServicesFormFieldValue
      > = {};

      if (
        isDraft &&
        (this.props as any).multiple &&
        (!(currentValue as MultipleRequestedServicesFormFieldValue)
          ?.draftValues ||
          (currentValue as MultipleRequestedServicesFormFieldValue)?.draftValues
            .length == 0) &&
        !this.props['hasPossibleEmptyPendingChanges']
      ) {
        additionalAdjustments = {
          ...additionalAdjustments,
          draftValues: [
            ...(currentValue as MultipleRequestedServicesFormFieldValue).values,
          ],
        };
      } else if (
        isDraft &&
        !(this.props as any).multiple &&
        !(currentValue as SingleRequestedServicesFormFieldValue)?.draftValue
      ) {
        additionalAdjustments = {
          ...additionalAdjustments,
          draftValue: (currentValue as SingleRequestedServicesFormFieldValue)
            .value,
        };
      }

      this.formControl.setValue({
        ...currentValue,
        isDraft,
        ...additionalAdjustments,
      });
    });

    if ((this.props as any).externalDraftToggleObservable) {
      (
        (this.props as any).externalDraftToggleObservable as Observable<boolean>
      ).subscribe((isDraft) => {
        this.isDraft$.next(isDraft);
      });
    }
  }

  async onOptionClicked(value: any) {
    this.formControl.markAsDirty();
    const isDraft = await firstValueFrom(this.isDraft$);
    const currentValue = await firstValueFrom(this.value$);
    let newValue;
    if ((this.props as any).multiple) {
      if (currentValue) {
        if (isDraft) {
          const draftContains = (
            currentValue as MultipleRequestedServicesFormFieldValue
          ).draftValues.includes(value);

          newValue = {
            ...currentValue,
            draftValues: draftContains
              ? (
                  currentValue as MultipleRequestedServicesFormFieldValue
                ).draftValues.filter((item) => item != value)
              : [
                  value,
                  ...(currentValue as MultipleRequestedServicesFormFieldValue)
                    .draftValues,
                ],
          };
        } else {
          const valuesContains = (
            currentValue as MultipleRequestedServicesFormFieldValue
          ).values.includes(value);

          newValue = {
            ...currentValue,
            values: valuesContains
              ? (
                  currentValue as MultipleRequestedServicesFormFieldValue
                ).values.filter((item) => item != value)
              : [
                  value,
                  ...(currentValue as MultipleRequestedServicesFormFieldValue)
                    .values,
                ],
          };
        }
      } else {
        newValue = {
          isDraft,
          draftValues: isDraft ? [value] : [],
          values: isDraft ? [] : [value],
        };
      }
    } else {
      if (currentValue) {
        newValue = {
          ...currentValue,
          draftValue: isDraft
            ? value
            : (currentValue as SingleRequestedServicesFormFieldValue)
                .draftValue,
          value: isDraft
            ? (currentValue as SingleRequestedServicesFormFieldValue).value
            : value,
        };
      } else {
        newValue = {
          draftValue: isDraft ? value : undefined,
          value: isDraft ? undefined : value,
          isDraft,
        };
      }
    }

    this.formControl.setValue(newValue);
  }

  async toggleIsDraft() {
    this.isDraft$.next(!(await firstValueFrom(this.isDraft$)));
  }

  getOptionValueChecked(value: any) {
    return combineLatest([this.isDraft$, this.value$]).pipe(
      map(([isDraft, currentValue]) => {
        if (isDraft) {
          if ((this.props as any).multiple) {
            return !!(
              currentValue as MultipleRequestedServicesFormFieldValue
            )?.draftValues?.find((i) => i == value);
          } else {
            return (
              (currentValue as SingleRequestedServicesFormFieldValue)
                ?.draftValue == value
            );
          }
        } else {
          if ((this.props as any).multiple) {
            return !!(
              currentValue as MultipleRequestedServicesFormFieldValue
            )?.values?.find((i) => i == value);
          } else {
            return (
              (currentValue as SingleRequestedServicesFormFieldValue)?.value ==
              value
            );
          }
        }
      }),
    );
  }
}
