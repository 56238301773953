import { AfterViewInit, Component, Input } from '@angular/core';
import {
  BehaviorSubject,
  firstValueFrom,
  map,
  Observable,
  switchMap,
} from 'rxjs';
import { CommonModule } from '@angular/common';
import { CoreAppState } from '../../state/core-app.state';
import { formatDateTime, formatUSD } from '@aa/ts/common';
import {
  INVOICE_STATUS,
  INVOICE_PAYMENT_STATUS,
  customerFacingInvoiceStatusLabels,
  invoiceStatusLabels,
  invoiceStatusColorMap,
  REFUND_ITEM_STATUS,
  refundItemStatusColorMap,
} from '@aa/nest/resource/objects';
import { invoiceActions } from '../../state/invoice/invoice.actions';
import { InvoiceComponent } from '../invoice/invoice.component';
import {
  InvoiceResourceTypeMappings,
  OrderResourceTypeMappings,
} from '@aa/nest/resource';
import { MatButtonModule } from '@angular/material/button';
import { selectInvoiceState } from '../../state/invoice/invoice.reducer';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { selectOrderState } from '../../state/order/order.reducer';

@Component({
  selector: 'aa-invoice-history',
  standalone: true,
  imports: [CommonModule, MatButtonModule, InvoiceComponent],
  templateUrl: './invoice-history.component.html',
  styleUrls: ['./invoice-history.component.scss'],
})
export class InvoiceHistoryComponent implements AfterViewInit {
  @Input() orderId!: number;
  @Input() hideTitle = false;
  @Input() showAsCustomerView = true;

  invoices$: Observable<
    InvoiceResourceTypeMappings['resourceWithRelationsT'][]
  >;
  order$: Observable<
    OrderResourceTypeMappings['resourceWithRelationsT'] | null
  >;
  currentInvoiceIndex$ = new BehaviorSubject<number>(0);

  currentInvoice$: Observable<
    InvoiceResourceTypeMappings['resourceWithRelationsT'] | null
  >;

  constructor(private readonly store: Store<CoreAppState>) {
    this.invoices$ = this.store
      .select((s) => selectInvoiceState(s).items)
      .pipe(takeUntilDestroyed());
    this.order$ = this.store
      .select((s) => selectOrderState(s).current)
      .pipe(takeUntilDestroyed());

    this.currentInvoice$ = this.currentInvoiceIndex$.pipe(
      switchMap((index) =>
        this.invoices$.pipe(map((invoices) => invoices[index] ?? null)),
      ),
    );
  }

  ngAfterViewInit() {
    this.order$.subscribe(async (order) => {
      const isStaffApp = await firstValueFrom(
        this.store.select((s) => s.isStaffApp),
      );
      if (isStaffApp || order?.showChangesToCustomer) {
        this.store.dispatch(
          invoiceActions.loadItems({
            query: {
              where: {
                orderId: this.orderId,
              },
              include: {
                invoiceItems: {
                  include: {
                    orderServiceLinkages: true,
                    orderCardServiceLinkages: {
                      include: {
                        orderCard: true,
                      },
                    },
                    refundItems: true,
                  },
                },
                invoicePayments: true,
                refundItems: {
                  include: {
                    invoiceItem: true,
                  },
                },
              },
              orderBy: {
                createdAt: 'desc',
              },
            },
          }),
        );
      } else {
        this.store.dispatch(
          invoiceActions.loadItems({
            query: {
              where: {
                orderId: this.orderId,
                OR: [
                  { status: INVOICE_STATUS.PAID },
                  { status: INVOICE_STATUS.PENDING },
                ],
              },
              include: {
                invoiceItems: {
                  include: {
                    orderServiceLinkages: true,
                    orderCardServiceLinkages: {
                      include: {
                        orderCard: true,
                      },
                    },
                    refundItems: {
                      where: {
                        status: REFUND_ITEM_STATUS.REFUNDED,
                      },
                    },
                  },
                },
                invoicePayments: {
                  where: {
                    OR: [
                      { status: INVOICE_PAYMENT_STATUS.PAID },
                      { status: INVOICE_PAYMENT_STATUS.PENDING },
                    ],
                  },
                },
                refundItems: {
                  where: {
                    status: REFUND_ITEM_STATUS.REFUNDED,
                  },
                  include: {
                    invoiceItem: true,
                  },
                },
              },
              orderBy: {
                createdAt: 'desc',
              },
            },
          }),
        );
      }
    });
  }

  async setCurrentInvoiceIndex(index: number) {
    this.currentInvoiceIndex$.next(index);
  }

  getInvoiceStatusLabel = (status: INVOICE_STATUS) =>
    this.showAsCustomerView
      ? customerFacingInvoiceStatusLabels[status]
      : invoiceStatusLabels[status];

  getInvoiceStatusCSS = (status: INVOICE_STATUS) => ({
    backgroundColor: invoiceStatusColorMap[status][0],
    color: invoiceStatusColorMap[status][1],
  });

  hasPendingRefunds(
    invoice: InvoiceResourceTypeMappings['resourceWithRelationsT'],
  ) {
    return invoice.refundItems?.find(
      (refund) => refund.status == REFUND_ITEM_STATUS.STAFF_AUTO_DRAFT,
    );
  }

  getPendingRefundsCSS() {
    return {
      backgroundColor:
        refundItemStatusColorMap[REFUND_ITEM_STATUS.STAFF_AUTO_DRAFT][0],
      color: refundItemStatusColorMap[REFUND_ITEM_STATUS.STAFF_AUTO_DRAFT][1],
    };
  }

  formatMoney = formatUSD;

  formatDateTime = formatDateTime;
}
