import { CoreAppState } from '../state/core-app.state';
import { BaseResourceService, httpOptions } from './base-resource.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrderResourceTypeMappings } from '@aa/nest/resource';

@Injectable()
export class OrderService extends BaseResourceService<OrderResourceTypeMappings> {
  constructor(
    protected override readonly http: HttpClient,
    protected override readonly store: Store<CoreAppState>,
  ) {
    super(http, store, 'orders');
  }

  sendChangeNotification(id: OrderResourceTypeMappings['resourcePrimaryKeyT']) {
    const idSlug = this.getPrimaryKeyIdSlug(id);

    return this.http.post<OrderResourceTypeMappings['resourceT']>(
      `${this.endpoint}/${this.resourceSlug}/send-change-notification${idSlug}`,
      {},
      {
        ...httpOptions,
      },
    );
  }

  setChangeApprovalStatus(
    id: OrderResourceTypeMappings['resourcePrimaryKeyT'],
    approved: boolean,
  ) {
    const idSlug = this.getPrimaryKeyIdSlug(id);

    return this.http.post<OrderResourceTypeMappings['resourceT']>(
      `${this.endpoint}/${this.resourceSlug}/set-change-approval-status${idSlug}`,
      {
        approved,
      },
      {
        ...httpOptions,
      },
    );
  }
}
