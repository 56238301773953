<aa-view-bar
  [backPath]="(backPath$ | async)!"
  title="Review Cards"></aa-view-bar>

<div class="customer-view-container v-scrollable">
  <aa-review-cards></aa-review-cards>
</div>

<div class="customer-view-actions">
  <button
    mat-flat-button
    color="primary"
    [routerLink]="'/draft-editor/' + (draftId$ | async) + '/checkout'">
    Checkout
  </button>
</div>
