@if (!hideTitle) {
  <h2>Invoices</h2>
}
<div class="container">
  @if (!(invoices$ | async) || (invoices$ | async)!.length == 0) {
    <p>No invoices yet.</p>
  }

  <div class="sidebar card">
    @for (invoice of invoices$ | async; track $index) {
      <div
        class="invoice-preview"
        [ngClass]="{ selected: (currentInvoiceIndex$ | async) == $index }"
        (click)="setCurrentInvoiceIndex($index)">
        <p class="title">
          Invoice #I_{{ invoice.id }} - {{ formatDateTime(invoice.createdAt) }}
        </p>
        <p class="status" [ngStyle]="getInvoiceStatusCSS(invoice.status)">
          {{ getInvoiceStatusLabel(invoice.status) }}
        </p>
        @if (hasPendingRefunds(invoice)) {
          <p class="status" [ngStyle]="getPendingRefundsCSS()">
            Drafted Refunds
          </p>
        }
        <div class="briefs">
          <p>{{ invoice.invoiceItems?.length ?? 0 }} Items</p>
          <p>{{ invoice.refundItems?.length ?? 0 }} Refunds</p>
          <p>{{ invoice.invoicePayments?.length ?? 0 }} Payments</p>
        </div>
        <p class="total">Total: {{ formatMoney($any(invoice.amount)) }}</p>
      </div>
    }
  </div>
  @if (currentInvoice$ | async) {
    <aa-invoice
      class="card"
      [showAsCustomerView]="showAsCustomerView"
      [invoice]="(currentInvoice$ | async)!"></aa-invoice>
  }
</div>
