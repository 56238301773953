import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BaseResourceEffects } from '../base-resource.effects';
import { catchError, delay, map, mergeMap, of, switchMap, take } from 'rxjs';
import { CoreAppState } from '../core-app.state';
import { Injectable } from '@angular/core';
import { invoiceActions } from './invoice.actions';
import { invoiceItemActions } from '../invoice-item/invoice-item.actions';
import { InvoiceResourceTypeMappings } from '@aa/nest/resource';
import { InvoiceService } from '../../services/invoice.service';
import { PaymentService } from '../../services/payment.service';
import { selectInvoiceState } from './invoice.reducer';
import { Store } from '@ngrx/store';
import { refundItemActions } from '../refund-item/refund-item.actions';
import { invoicePaymentActions } from '../invoice-payment/invoice-payment.actions';

// @aa:gen-ignore

@Injectable()
export class InvoiceEffects extends BaseResourceEffects<InvoiceResourceTypeMappings> {
  autoAdjust$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.autoAdjustForOrder),
      mergeMap((payload) =>
        this.store
          .select((s) => this.stateSelector(s).currentQuery)
          .pipe(
            take(1),
            switchMap((currentQuery) =>
              this.paymentService.adjustInvoicingForOrder(payload.id).pipe(
                delay(1000),
                map((res) =>
                  this.itemActions.loadItems({
                    query: currentQuery as any,
                  }),
                ),
                catchError((err) =>
                  of(
                    this.itemActions.setError({
                      error:
                        err.error?.message?.toString() ??
                        err.message?.toString() ??
                        err.toString(),
                    }),
                  ),
                ),
              ),
            ),
          ),
      ),
    ),
  );

  finalize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.finalize),
      mergeMap((payload) =>
        this.store
          .select((s) => this.stateSelector(s).currentQuery)
          .pipe(
            take(1),
            switchMap((currentQuery) =>
              this.paymentService.finalizeInvoice(payload.id).pipe(
                delay(1000),
                map((res) =>
                  this.itemActions.loadItems({
                    query: currentQuery as any,
                  }),
                ),
                catchError((err) =>
                  of(
                    this.itemActions.setError({
                      error:
                        err.error?.message?.toString() ??
                        err.message?.toString() ??
                        err.toString(),
                    }),
                  ),
                ),
              ),
            ),
          ),
      ),
    ),
  );

  finalizeAllForOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.finalizeAllForOrder),
      mergeMap((payload) =>
        this.store
          .select((s) => this.stateSelector(s).currentQuery)
          .pipe(
            take(1),
            switchMap((currentQuery) =>
              this.paymentService.finalizeAllForOrder(payload.id).pipe(
                delay(1000),
                map((res) =>
                  this.itemActions.loadItems({
                    query: currentQuery as any,
                  }),
                ),
                catchError((err) =>
                  of(
                    this.itemActions.setError({
                      error:
                        err.error?.message?.toString() ??
                        err.message?.toString() ??
                        err.toString(),
                    }),
                  ),
                ),
              ),
            ),
          ),
      ),
    ),
  );

  cancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.cancel),
      mergeMap((payload) =>
        this.store
          .select((s) => this.stateSelector(s).currentQuery)
          .pipe(
            take(1),
            switchMap((currentQuery) =>
              this.paymentService.cancelInvoice(payload.id).pipe(
                map((res) =>
                  this.itemActions.loadItems({
                    query: currentQuery as any,
                  }),
                ),
                catchError((err) =>
                  of(
                    this.itemActions.setError({
                      error:
                        err.error?.message?.toString() ??
                        err.message?.toString() ??
                        err.toString(),
                    }),
                  ),
                ),
              ),
            ),
          ),
      ),
    ),
  );

  cancelAllForOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceActions.cancelAllForOrder),
      mergeMap((payload) =>
        this.store
          .select((s) => this.stateSelector(s).currentQuery)
          .pipe(
            take(1),
            switchMap((currentQuery) =>
              this.paymentService.cancelAllForOrder(payload.id).pipe(
                map((res) =>
                  this.itemActions.loadItems({
                    query: currentQuery as any,
                  }),
                ),
                catchError((err) =>
                  of(
                    this.itemActions.setError({
                      error:
                        err.error?.message?.toString() ??
                        err.message?.toString() ??
                        err.toString(),
                    }),
                  ),
                ),
              ),
            ),
          ),
      ),
    ),
  );

  constructor(
    protected override readonly actions$: Actions,
    protected override readonly store: Store<CoreAppState>,
    protected override readonly service: InvoiceService,
    private readonly paymentService: PaymentService,
  ) {
    super(actions$, store, service, invoiceActions, selectInvoiceState, [
      invoiceItemActions.setItems,
      invoicePaymentActions.setItems,
      refundItemActions.setItems,
    ]);
  }
}
