import { StaffProfileEffects } from '@aa/angular/core';
import { CustomerProfileEffects } from '@aa/angular/core';
import { OrderCardAttributeTypeEffects } from '@aa/angular/core';
import { OrderCardAttributeEffects } from '@aa/angular/core';
import { OrderCardEffects } from '@aa/angular/core';
import { OrderStatusEffects } from '@aa/angular/core';
import { OrderSourceEffects } from '@aa/angular/core';
import { OrderServiceLinkageEffects } from '@aa/angular/core';
import { OrderEffects } from '@aa/angular/core';
import { OfferedServiceEffects } from '@aa/angular/core';
import { OrderUpdateRequestEffects } from '@aa/angular/core';
import { RefreshTokenEffects } from '@aa/angular/core';
import { UserRoleAssignmentEffects } from '@aa/angular/core';
import { ActionLogEffects } from '@aa/angular/core';
import { AuthEffects, UserEffects, UserRoleEffects } from '@aa/angular/core';
import { InvoiceEffects } from '@aa/angular/core';
import { InvoiceItemEffects } from '@aa/angular/core';
import { InvoicePaymentEffects } from '@aa/angular/core';
import { OrderCardServiceLinkageEffects } from '@aa/angular/core';
import { RefundItemEffects } from '@aa/angular/core';

export const staffAppEffects = [
  AuthEffects,
  OrderEffects,
  OrderCardEffects,
  UserEffects,
  UserRoleEffects,
  UserRoleAssignmentEffects,
  RefreshTokenEffects,
  ActionLogEffects,
  OfferedServiceEffects,
  OrderServiceLinkageEffects,
  OrderSourceEffects,
  OrderStatusEffects,
  OrderCardAttributeEffects,
  OrderCardAttributeTypeEffects,
  CustomerProfileEffects,
  StaffProfileEffects,
  OrderCardServiceLinkageEffects,
  InvoiceEffects,
  InvoiceItemEffects,
  InvoicePaymentEffects,
  RefundItemEffects,
  OrderUpdateRequestEffects,
];
