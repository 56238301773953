<div class="container">
  @if ((hideLayout$ | async) !== true) {
    <aa-app-bar (showInstallPrompt)="showInstallPrompt()"></aa-app-bar>
  }
  <div [ngClass]="{ 'content-holder': !(hideLayout$ | async) }">
    <ng-content></ng-content>

    @if ((showHelpButton$ | async) == true) {
      <button
        id="help-button"
        mat-fab
        color="primary"
        matTooltip="Help"
        matTooltipPosition="left"
        #helpTooltip="matTooltip"
        (click)="showHelp()">
        <mat-icon>help</mat-icon>
      </button>
    }
  </div>
</div>

<pwa-install
  #pwaInstallPrompt
  disable-screenshots="true"
  disable-screenshots-apple="true"
  disable-screenshots-chrome="true"
  disable-install-description="true"
  description="Install the All Angles app for easy access and a more native experience."
  manifest-url="/manifest.webmanifest"></pwa-install>
