import { InvoiceResourceTypeMappings } from '@aa/nest/resource';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  BaseFormModalComponent,
  baseFormModalImports,
  BaseResourceFormModelOptionsMappers,
  BaseResourceFormModalOptionsMapper,
  FormModalData,
} from '../../components/base-form-modal/base-form-modal.component';
import { CoreAppState } from '../../state/core-app.state';
import { invoiceActions } from '../../state/invoice/invoice.actions';
import { selectOrderState } from '../../state/order/order.reducer';
import { orderActions } from '../../state/order/order.actions';

export type InvoiceFormModel = Partial<
  InvoiceResourceTypeMappings['createDTO']
>;
export type InvoiceFormModalData = FormModalData<InvoiceFormModel>;

@Component({
  selector: 'aa-invoice-form-modal',
  standalone: true,
  imports: [...baseFormModalImports],
  templateUrl:
    '../../components/base-form-modal/base-form-modal.component.html',
  styleUrl: '../../components/base-form-modal/base-form-modal.component.scss',
})
export class InvoiceFormModalComponent extends BaseFormModalComponent<
  InvoiceFormModel,
  InvoiceFormModalData
> {
  title = 'Invoice';
  fields: FormlyFieldConfig[] = [
    {
      key: 'orderId',
      type: 'select',
      props: {
        label: 'Order Id',
        required: true,
        options: [],
      },
    },
    {
      key: 'amount',
      type: 'input',
      props: {
        label: 'Amount',
        type: 'number',
        required: true,
      },
    },
    {
      key: 'status',
      type: 'input',
      props: {
        label: 'Status',
        type: 'number',
        required: false,
      },
    },
  ];

  createAction = invoiceActions.createItem;
  updateAction = invoiceActions.updateItem;

  override optionsMappers: BaseResourceFormModelOptionsMappers = {
    orderId: {
      loadAction: orderActions.loadItems({}),
      stateSelector: selectOrderState,
    },
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected override readonly data: InvoiceFormModalData,
    protected override readonly dialogRef: MatDialogRef<InvoiceFormModalComponent>,
    protected override readonly store: Store<CoreAppState>,
  ) {
    super(data, dialogRef, store);
  }
}
