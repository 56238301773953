import {
  orderCardActions,
  selectOrderCardState,
  StorageService,
} from '@aa/angular/core';
import {
  CardFormModel,
  reduceFieldsToKeys,
  cardFormArrayFields,
  getCardFormFieldsAndLoadOptions,
} from '@aa/angular/customer';
import {
  OrderCardResourceTypeMappings,
  UpdateOrderCardDTO,
} from '@aa/nest/resource';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import {
  BehaviorSubject,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
} from 'rxjs';
import { StaffAppState } from '../../state/index.reducers';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  MultipleRequestedServicesFormFieldValue,
  RequestedServicesFormFieldValue,
  SingleRequestedServicesFormFieldValue,
} from '../requested-services-form-field/requested-services-form-field.component';

const flatLevelAttributes = [
  'value',
  'requestedGradingCompany',
  'customerFacingNotes',
];

export type StaffCardFormModel = Omit<CardFormModel, 'requestedServices'> & {
  requestedServices: RequestedServicesFormFieldValue;
  customerFacingNotes?: string;
};

@Component({
  selector: 'aa-order-cards-tab',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    FormlyModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './order-cards-tab.component.html',
  styleUrl: './order-cards-tab.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderCardsTabComponent {
  form = new FormGroup({});
  model: StaffCardFormModel = {
    requestedServices: {
      draftValues: [],
      values: [],
    },
    cardNumber: '',
    characterName: '',
    issuedYear: '',
    notes: '',
    playerName: '',
    pokemonType: '',
    productionCompany: '',
    serialNumber: '',
    setName: '',
    variation: '',
  };
  fields: FormlyFieldConfig[] = [];
  hideForm$ = new BehaviorSubject(false);

  orderId$: Observable<number>;
  cards$: Observable<OrderCardResourceTypeMappings['resourceWithRelationsT'][]>;
  cardsLoading$: Observable<boolean>;

  currentCard$ = new BehaviorSubject<
    OrderCardResourceTypeMappings['resourceWithRelationsT'] | null
  >(null);
  aiLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly store: Store<StaffAppState>,
    private readonly route: ActivatedRoute,
    private readonly storageService: StorageService,
    private readonly destroyRef: DestroyRef,
  ) {
    this.orderId$ = this.route.paramMap.pipe(
      map((paramMap) => parseInt(paramMap.get('id')!)),
      takeUntilDestroyed(),
    );
    this.cards$ = this.store
      .select((s) => selectOrderCardState(s).items)
      .pipe(takeUntilDestroyed());
    this.cardsLoading$ = this.store
      .select((s) => selectOrderCardState(s).itemsLoading)
      .pipe(takeUntilDestroyed());

    getCardFormFieldsAndLoadOptions(store, this.destroyRef, true).subscribe(
      (formFields) => {
        this.fields = [
          {
            template: '<p><strong>Provided Details</strong><p/>',
          },
          ...formFields,
        ];
      },
    );

    this.orderId$.subscribe((orderId) => {
      this.store.dispatch(
        orderCardActions.loadItems({
          query: {
            where: {
              orderId,
            },
            include: {
              orderCardServiceLinkages: {
                include: {
                  invoiceItem: true,
                  refundItem: true,
                  offeredService: {
                    include: {
                      parentOfferedService: true,
                    },
                  },
                },
              },
              orderCardAttributes: {
                include: {
                  orderCardAttributeType: true,
                },
              },
            },
          },
        }),
      );
    });

    this.currentCard$?.subscribe((card) => {
      const tradingCardType = card?.orderCardAttributes?.find(
        (ca) => ca.orderCardAttributeType?.fieldName == 'tradingCardType',
      )?.value;

      const patchValue: Record<string, any> = {
        // ...this.model,
        tradingCardType,
        requestedServices: {
          draftValues: [
            ...(card?.orderCardServiceLinkages
              ?.filter(
                (li) =>
                  !li.offeredService?.parentOfferedServiceId && li.isDraft,
              )
              ?.map((sl) => sl.offeredServiceId) ?? []),
          ],
          values: [
            ...(card?.orderCardServiceLinkages
              ?.filter(
                (li) =>
                  !li.offeredService?.parentOfferedServiceId && !li.isDraft,
              )
              ?.map((sl) => sl.offeredServiceId) ?? []),
          ],
        },
        requestedGradingCompany: card?.requestedGradingCompany,
        ...card?.orderCardAttributes
          ?.filter((attribute) => attribute.value)
          ?.reduce(
            (obj, attribute) => ({
              ...obj,
              [attribute.orderCardAttributeType?.fieldName]:
                cardFormArrayFields.includes(
                  attribute.orderCardAttributeType?.fieldName,
                ) &&
                attribute.value &&
                attribute.value.includes('[') &&
                attribute.value.includes(']')
                  ? JSON.parse(attribute.value)
                  : attribute.value,
            }),
            {} as CardFormModel,
          ),

        value: card?.value as any,
        customerFacingNotes: card?.customerFacingNotes as any,
      };

      for (const serviceLinkage of card?.orderCardServiceLinkages ?? []) {
        const service = serviceLinkage.offeredService;
        if (service.parentOfferedService) {
          const key = `${(
            service.parentOfferedService?.abbreviation ??
            service.parentOfferedService?.name
          )
            .toLowerCase()
            .replaceAll(' ', '')}${service.group?.replaceAll(' ', '')}Addon`;

          if (patchValue[key]) {
            if (serviceLinkage.isDraft && !patchValue[key].draftValue) {
              patchValue[key] = { ...patchValue[key], draftValue: service.id };
            } else if (!serviceLinkage.isDraft && !patchValue[key].value) {
              patchValue[key] = { ...patchValue[key], value: service.id };
            } else if (
              patchValue[key]?.values ||
              patchValue[key]?.draftValues
            ) {
              const v: MultipleRequestedServicesFormFieldValue =
                patchValue[key];

              if (serviceLinkage.isDraft) {
                patchValue[key] = {
                  ...v,
                  draftValues: [...v.draftValues, service.id],
                };
              } else {
                patchValue[key] = {
                  ...v,
                  values: [...v.values, service.id],
                };
              }
            } else {
              const v: SingleRequestedServicesFormFieldValue = patchValue[key];

              if (serviceLinkage.isDraft) {
                patchValue[key] = {
                  draftValues: v.draftValue
                    ? [v.draftValue, service.id]
                    : [service.id],
                  values: v.value ? [v.value] : [],
                };
              } else {
                patchValue[key] = {
                  draftValues: v.draftValue ? [v.draftValue] : [],
                  values: v.value ? [v.value, service.id] : [service.id],
                };
              }
            }
          } else {
            if (serviceLinkage.isDraft) {
              patchValue[key] = {
                draftValue: service.id,
              };
            } else {
              patchValue[key] = {
                value: service.id,
              };
            }
          }
        }
      }

      this.model = { ...(patchValue as any) };
    });
  }

  async setCurrentCard(
    orderCard: OrderCardResourceTypeMappings['resourceWithRelationsT'] | null,
  ) {
    this.form.reset();
    this.hideForm$.next(true);
    setTimeout(() => {
      this.hideForm$.next(false);
    }, 1);
    if (orderCard != null) {
      const copyOfOrderCard = structuredClone(orderCard);
      copyOfOrderCard.frontImageURL = (
        await lastValueFrom(
          this.storageService.getFreshURL(
            copyOfOrderCard.frontImageStorageSlug ?? '',
          ),
        )
      ).url;
      copyOfOrderCard.backImageURL = (
        await lastValueFrom(
          this.storageService.getFreshURL(
            copyOfOrderCard.backImageStorageSlug ?? '',
          ),
        )
      ).url;
      this.currentCard$.next(copyOfOrderCard);
    } else {
      this.currentCard$.next(orderCard);
    }
  }

  async handleImageClick(isBackImage = false) {
    const currentCard = await firstValueFrom(this.currentCard$);
    if (currentCard) {
      const newSrc = (
        await lastValueFrom(
          this.storageService.getFreshURL(
            isBackImage
              ? currentCard.backImageStorageSlug!
              : currentCard.frontImageStorageSlug!,
          ),
        )
      )?.url;
      this.enlargePhoto(newSrc);
    }
  }

  enlargePhoto(src: string) {
    const img = new Image();
    img.src = src;
    // const photoViewer = new PhotoViewer([img], {
    //   title: false,
    // });
  }

  cancel() {
    this.setCurrentCard(null);
  }

  async save() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const card = await firstValueFrom(this.currentCard$);
    const cardId = card?.id;
    const currentOrderCardAttributes = card?.orderCardAttributes;

    let draftRequestedServices = [
      ...(
        this.model.requestedServices as MultipleRequestedServicesFormFieldValue
      ).draftValues,
    ];
    let actualRequestedServices = [
      ...(
        this.model.requestedServices as MultipleRequestedServicesFormFieldValue
      ).values,
    ];

    // let requestedServices = [
    //   ...Object.entries(this.model.requestedServices)
    //     .filter(([key, value]) => value == true)
    //     .map(([key, value]) => parseInt(key)),
    // ];
    for (const [key, value] of Object.entries(this.model)) {
      if (key.substring(key.length - 5) == 'Addon') {
        if ((value as any).values) {
          actualRequestedServices = [
            ...actualRequestedServices,
            ...(value as MultipleRequestedServicesFormFieldValue).values,
          ];
          draftRequestedServices = [
            ...draftRequestedServices,
            ...(value as MultipleRequestedServicesFormFieldValue).draftValues,
          ];
        } else {
          if ((value as SingleRequestedServicesFormFieldValue).value)
            actualRequestedServices = [
              ...actualRequestedServices,
              (value as SingleRequestedServicesFormFieldValue).value,
            ];
          if ((value as SingleRequestedServicesFormFieldValue).draftValue)
            draftRequestedServices = [
              ...draftRequestedServices,
              (value as SingleRequestedServicesFormFieldValue).draftValue,
            ];
        }
      }
    }

    const model: any = { ...this.form.value };
    const attributeKeys = reduceFieldsToKeys(this.fields);

    let generatedCardLabel = (
      ['pokemon'].includes(model.tradingCardType!)
        ? `${model.characterName ?? ''} ${model.issuedYear ?? ''} ${
            model.setName ?? ''
          } ${model.cardNumber ? `#${model.cardNumber}` : ''}`
        : `${model.playerName} ${model.issuedYear} ${model.issue ?? ''} ${
            model.cardNumber ? `#${model.cardNumber}` : ''
          } ${model.serialNumber ?? ''}`
    )?.replaceAll('  ', ' ');
    if (generatedCardLabel.endsWith(' ')) {
      generatedCardLabel = generatedCardLabel.substring(
        0,
        generatedCardLabel.length - 1,
      );
    }

    if (cardId) {
      this.store.dispatch(
        orderCardActions.updateCardWithAttributes({
          id: cardId,
          cardUpdates: {
            ...(Object.entries(model)
              .filter(([key, value]) => flatLevelAttributes.includes(key))
              .reduce(
                (obj, [key, value]) => ({
                  ...obj,
                  [key]: value,
                }),
                {} as UpdateOrderCardDTO,
              ) ?? {}),
            label: generatedCardLabel,
          },
          offeredServicesIds: actualRequestedServices,
          draftOfferedServicesIds: draftRequestedServices,
          attributes: attributeKeys
            .filter(
              (key) =>
                key !== 'requestedServices' &&
                key.substring(key.length - 5) != 'Addon' &&
                !flatLevelAttributes.includes(key),
            )
            .map((key) => ({
              orderCardId: cardId,
              orderCardAttributeTypeId: currentOrderCardAttributes?.find(
                (ca) => ca.orderCardAttributeType.fieldName == key,
              )?.orderCardAttributeTypeId,
              fieldName: key,
              value:
                cardFormArrayFields.includes(key) && model[key]
                  ? JSON.stringify(model[key])
                  : model[key],
            })),
        }),
      );
    }
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }
}
