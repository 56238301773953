<div class="container">
  @if ((hideLayout$ | async) !== true) {
    <aa-app-bar (showInstallPrompt)="showInstallPrompt()"></aa-app-bar>
  }
  <mat-drawer-container [ngClass]="{ 'hidden-layout': hideLayout$ | async }">
    @if ((hideLayout$ | async) !== true) {
      <mat-drawer mode="side" [opened]="sidenavIsOpen$ | async">
        <mat-list>
          @for (sidenavItem of sidenavItems; track sidenavItem.key) {
            <mat-list-item
              (click)="toggleExpandedItem(sidenavItem)"
              [routerLink]="sidenavItem.routerPath">
              @if (sidenavItem.icon) {
                <mat-icon matListItemIcon> {{ sidenavItem.icon }}</mat-icon>
              }
              <div matListItemTitle>
                {{ sidenavItem.label }}

                @if (sidenavItem.children) {
                  <mat-icon>{{
                    (expandedItems$ | async)?.includes(sidenavItem.key)
                      ? 'expand_less'
                      : 'expand_more'
                  }}</mat-icon>
                }
              </div>
            </mat-list-item>

            @if (
              sidenavItem.children &&
              (expandedItems$ | async)?.includes(sidenavItem.key)
            ) {
              <mat-list>
                @for (child of sidenavItem.children; track child.key) {
                  <mat-list-item
                    class="child"
                    [routerLink]="child.routerPath ?? null">
                    @if (child.icon) {
                      <mat-icon matListItemIcon>{{ child.icon }}</mat-icon>
                    }
                    <div matListItemTitle>{{ child.label }}</div>
                  </mat-list-item>
                }
              </mat-list>
            }
          }
        </mat-list>
      </mat-drawer>
    }

    <div [ngClass]="{ 'content-holder': !(hideLayout$ | async) }">
      <ng-content></ng-content>
    </div>
  </mat-drawer-container>
</div>

<pwa-install
  #pwaInstallPrompt
  disable-screenshots="true"
  disable-screenshots-apple="true"
  disable-screenshots-chrome="true"
  disable-install-description="true"
  description="Install the All Angles app for easy access and a more native experience."
  manifest-url="/manifest.webmanifest"></pwa-install>
