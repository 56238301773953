import {
  CustomerProfileResourceTypeMappings,
  OrderResourceTypeMappings,
} from '@aa/nest/resource';
import { Component, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { debounceTime, startWith } from 'rxjs';
import {
  BaseFormModalComponent,
  baseFormModalImports,
  BaseResourceFormModelOptionsMappers,
  FormModalData,
} from '../../components/base-form-modal/base-form-modal.component';
import { CoreAppState } from '../../state/core-app.state';
import { customerProfileActions } from '../../state/customer-profile/customer-profile.actions';
import { selectCustomerProfileState } from '../../state/customer-profile/customer-profile.reducer';
import { offeredServiceActions } from '../../state/offered-service/offered-service.actions';
import { selectOfferedServiceState } from '../../state/offered-service/offered-service.reducer';
import { orderSourceActions } from '../../state/order-source/order-source.actions';
import { selectOrderSourceState } from '../../state/order-source/order-source.reducer';
import { orderStatusActions } from '../../state/order-status/order-status.actions';
import { selectOrderStatusState } from '../../state/order-status/order-status.reducer';
import { orderActions } from '../../state/order/order.actions';
import { MultipleRequestedServicesFormFieldValue } from '@aa/angular/staff';

// @aa:gen-ignore

export type OrderFormModel = Partial<
  OrderResourceTypeMappings['createDTO'] & {
    requestedServices?: MultipleRequestedServicesFormFieldValue;
  }
>;
export type OrderFormModalData = FormModalData<OrderFormModel>;

@Component({
  selector: 'aa-order-form-modal',
  standalone: true,
  imports: [...baseFormModalImports],
  templateUrl:
    '../../components/base-form-modal/base-form-modal.component.html',
  styleUrl: '../../components/base-form-modal/base-form-modal.component.scss',
})
export class OrderFormModalComponent extends BaseFormModalComponent<
  OrderFormModel,
  OrderFormModalData
> {
  title = 'Order';
  fields: FormlyFieldConfig[] = [
    {
      key: 'customerProfileId',
      hide: true,
      props: {
        required: true,
      },
    },
    {
      fieldGroupClassName: 'flex-row-field-group',
      fieldGroup: [
        {
          key: 'name',
          type: 'input',
          props: {
            label: 'Submission Name',
            required: true,
          },
        },
        {
          key: 'customerProfile',
          type: 'autocomplete',
          props: {
            label: 'Customer',
            required: true,
            options: this.store
              .select((s) => selectCustomerProfileState(s).items)
              .pipe(takeUntilDestroyed()),
            getLabel: (
              item: CustomerProfileResourceTypeMappings['resourceT'] | string,
            ) =>
              typeof item == 'object'
                ? `${item.firstName} ${item.lastName}`
                : item,
          },
          validators: ['existing-resource'],
          hooks: {
            onInit: (field) => {
              field.formControl?.valueChanges
                .pipe(
                  startWith(field.formControl.value ?? ''),
                  debounceTime(300),
                )
                .subscribe((searchString) => {
                  if (typeof searchString == 'string') {
                    this.store.dispatch(
                      customerProfileActions.loadItems({
                        query: {
                          where: {
                            AND: searchString
                              .split(' ')
                              .map((searchStringPart) => ({
                                OR: [
                                  {
                                    firstName: {
                                      contains: searchStringPart,
                                    },
                                  },
                                  {
                                    lastName: {
                                      contains: searchStringPart,
                                    },
                                  },
                                ],
                              })),
                          },
                        },
                      }),
                    );
                  } else if (typeof searchString == 'object') {
                    this.model.customerProfileId = searchString.id;
                  }
                });
            },
          },
        },
      ],
    },
    {
      key: 'requestedServices',
      type: 'requested-services',
      props: {
        label: 'Requested Services',
        multiple: true,
        required: true,
      },
    },
    {
      fieldGroupClassName: 'flex-row-field-group',
      fieldGroup: [
        {
          key: 'orderSourceId',
          type: 'select',
          props: {
            label: 'Order Source',
          },
        },
        {
          key: 'orderStatusId',
          type: 'select',
          props: {
            label: 'Order Status',
            required: true,
          },
        },
      ],
    },
    {
      key: 'receiptDate',
      type: 'date-time',
      props: {
        label: 'Receipt Date',
        required: false,
      },
    },
    {
      key: 'totalValue',
      type: 'input',
      props: {
        label: 'Total Value',
      },
    },
    {
      key: 'notes',
      type: 'textarea',
      props: {
        label: 'Notes',
        rows: 4,
      },
    },
    {
      key: 'customerFacingNotes',
      type: 'textarea',
      props: {
        label: 'Customer Facing Notes',
        rows: 4,
      },
    },
  ];

  createAction = orderActions.createItem;
  updateAction = orderActions.updateItem;

  override optionsMappers: BaseResourceFormModelOptionsMappers = {
    requestedServices: {
      stateSelector: selectOfferedServiceState,
      loadAction: offeredServiceActions.loadItems({
        query: {
          where: {
            level: 0,
          },
        },
      }),
    },
    orderSourceId: {
      stateSelector: selectOrderSourceState,
      loadAction: orderSourceActions.loadItems({}),
    },
    orderStatusId: {
      stateSelector: selectOrderStatusState,
      loadAction: orderStatusActions.loadItems({}),
    },
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected override readonly data: OrderFormModalData,
    protected override readonly dialogRef: MatDialogRef<OrderFormModalComponent>,
    protected override readonly store: Store<CoreAppState>,
  ) {
    super(data, dialogRef, store);
    this.dontStripKeys = ['hasPendingServiceChanges'];
  }

  override async ngOnInit() {
    super.ngOnInit();

    if (this.data.model?.hasPendingServiceChanges) {
      this.fields[2].props = {
        ...this.fields[2].props,
        hasPossibleEmptyPendingChanges: true,
      };
    }
  }

  override submit() {
    super.submit();

    if (this.data.mode == 'update') {
      let draftRequestedServices = [
        ...(
          this.model
            .requestedServices as MultipleRequestedServicesFormFieldValue
        ).draftValues,
      ];
      let actualRequestedServices = [
        ...(
          this.model
            .requestedServices as MultipleRequestedServicesFormFieldValue
        ).values,
      ];

      this.store.dispatch(
        offeredServiceActions.handleOrderLinkages({
          data: {
            orderId: (this.model as any)[this.primaryKeyField],
            linkages: actualRequestedServices.map((offeredServiceId) => ({
              offeredServiceId,
            })),
          },
        }),
      );
      this.store.dispatch(
        offeredServiceActions.handleOrderLinkages({
          data: {
            orderId: (this.model as any)[this.primaryKeyField],
            linkages: draftRequestedServices.map((offeredServiceId) => ({
              offeredServiceId,
              isDraft: true,
            })),
          },
          forDraft: true,
        }),
      );
    }
  }

  protected override mapModelBeforeSubmit? = (model: OrderFormModel) => {
    const copy = structuredClone(model);
    delete (copy as any).customerProfile;
    delete (copy as any).requestedServices;

    console.log('model', model.requestedServices?.isDraft);

    if (model.requestedServices?.isDraft) copy.hasPendingServiceChanges = true;
    return copy;
  };
}
