import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CoreAppState } from '../../state/core-app.state';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { formatDateTime, formatUSD } from '@aa/ts/common';
import {
  INVOICE_PAYMENT_STATUS,
  invoicePaymentStatusColorMap,
  invoicePaymentStatusLabels,
  REFUND_ITEM_STATUS,
  refundItemStatusColorMap,
  refundItemStatusLabels,
} from '@aa/nest/resource/objects';
import { INVOICE_STATUS } from '@aa/nest/resource/objects';
import { invoiceActions } from '../../state/invoice/invoice.actions';
import {
  InvoiceItemFormModalComponent,
  InvoiceItemFormModalData,
} from '../../forms/invoice-item-form-modal/invoice-item-form-modal.component';
import {
  InvoiceItemResourceTypeMappings,
  InvoicePaymentResourceTypeMappings,
  InvoiceResourceTypeMappings,
  RefundItemResourceTypeMappings,
} from '@aa/nest/resource';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { invoiceItemActions } from '../../state/invoice-item/invoice-item.actions';
import { MatTooltipModule } from '@angular/material/tooltip';
import { refundItemActions } from '../../state/refund-item/refund-item.actions';
import { invoicePaymentActions } from '../../state/invoice-payment/invoice-payment.actions';

interface InvoiceItemSummary {
  title: string;
  description?: string;
  costPerUnit: number;
  quantity: number;
  includedItems: InvoiceItemResourceTypeMappings['resourceWithRelationsT'][];
}

@Component({
  selector: 'aa-invoice',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatTooltipModule, MatIconModule],
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
})
export class InvoiceComponent implements OnChanges {
  @Input() invoice!: InvoiceResourceTypeMappings['resourceWithRelationsT'];
  @Input() showAsCustomerView = true;

  consolidatedInvoiceItems!: InvoiceItemSummary[];

  expandedItemIndexes$ = new BehaviorSubject<number[]>([]);

  isStaffApp$: Observable<boolean>;

  constructor(
    private readonly store: Store<CoreAppState>,
    private readonly dialog: MatDialog,
  ) {
    this.isStaffApp$ = this.store.select((s) => s.isStaffApp);
  }

  ngOnChanges() {
    this.consolidateInvoiceItems();
    this.expandedItemIndexes$.next([]);
  }

  consolidateInvoiceItems() {
    if (!this.invoice) return;
    let consolidatedItems: InvoiceItemSummary[] = [];
    for (const invoiceItem of (this.invoice?.invoiceItems ??
      []) as InvoiceItemResourceTypeMappings['resourceWithRelationsT'][]) {
      const existingIndex = consolidatedItems.findIndex(
        (item) =>
          item.costPerUnit == (invoiceItem.costPerUnit as any as number) &&
          item.title == invoiceItem.title &&
          item.description == invoiceItem.description,
      );
      if (existingIndex > -1) {
        const existing = consolidatedItems[existingIndex];
        consolidatedItems = [
          ...consolidatedItems.slice(0, existingIndex),
          {
            ...existing,
            quantity: existing.quantity + invoiceItem.quantity,
            includedItems: [...existing.includedItems, invoiceItem],
          },
          ...consolidatedItems.slice(existingIndex + 1),
        ];
      } else {
        consolidatedItems = [
          ...consolidatedItems,
          {
            title: invoiceItem.title,
            description: invoiceItem.description ?? undefined,
            costPerUnit: invoiceItem.costPerUnit as any as number,
            quantity: invoiceItem.quantity,
            includedItems: [invoiceItem],
          },
        ];
      }
    }

    this.consolidatedInvoiceItems = consolidatedItems;
  }

  async toggleItemExpansion(index: number) {
    const expandedItemIndexes = await firstValueFrom(this.expandedItemIndexes$);
    if (expandedItemIndexes.includes(index)) {
      this.expandedItemIndexes$.next(
        expandedItemIndexes.filter((i) => i !== index),
      );
    } else {
      this.expandedItemIndexes$.next([...expandedItemIndexes, index]);
    }
  }

  async openInvoiceItemForm(invoiceItemId?: number, linkageOnly = false) {
    const item = this.invoice.invoiceItems?.find(
      (invoice) => invoice.id == invoiceItemId,
    );
    const data: InvoiceItemFormModalData = {
      mode: item ? 'update' : 'create',
      model: {
        invoiceId: this.invoice.id,
        orderCardId: item?.orderCardServiceLinkages?.at(0)?.orderCardId,
        ...item,
      },
      meta: {
        orderId: this.invoice.orderId,
        linkageOnly,
      },
    } as any;
    this.dialog.open(InvoiceItemFormModalComponent, {
      data,
      minWidth: '50vw',
      maxWidth: 'calc(100vw - 3rem)',
      maxHeight: 'calc(100vh - 3rem)',
      autoFocus: false,
    });
  }

  async deleteInvoiceItem(invoiceItemId: number) {
    // const res = await firstValueFrom(
    //   this.dialog
    //     .open(ConfirmDialogComponent, {
    //       data: {
    //         title: 'Are you sure you want to remove this invoice item?',
    //         subtitle: '',
    //       },
    //     })
    //     .afterClosed(),
    // );

    // if (res) {
    this.store.dispatch(invoiceItemActions.deleteItem({ id: invoiceItemId }));
    // }
  }

  async openInvoiceRefundForm(invoiceItemId?: number) {}

  async finalize() {
    const res = await firstValueFrom(
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            title: 'Are you sure you want to finalize this invoice?',
            subtitle:
              'All pending payments will be charged to the customers default payment method.',
          },
        })
        .afterClosed(),
    );

    if (res) {
      this.store.dispatch(invoiceActions.finalize({ id: this.invoice?.id }));
    }
  }

  async finalizeInvoicePayment(id: number) {
    const res = await firstValueFrom(
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            title: 'Are you sure you want to finalize this invoice payment?',
            subtitle:
              'This payment will be charged to the customers default payment method.',
          },
        })
        .afterClosed(),
    );

    if (res) {
      this.store.dispatch(invoicePaymentActions.finalize({ id }));
    }
  }

  async finalizeRefundItem(id: number) {
    const res = await firstValueFrom(
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            title: 'Are you sure you want to finalize this refund item?',
            subtitle:
              'This refund will be issue to the customers default payment method.',
          },
        })
        .afterClosed(),
    );

    if (res) {
      this.store.dispatch(refundItemActions.finalize({ id }));
    }
  }

  async cancel() {
    const res = await firstValueFrom(
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            title: 'Are you sure you want to cancel this invoice?',
            subtitle: '',
          },
        })
        .afterClosed(),
    );

    if (res) {
      this.store.dispatch(invoiceActions.cancel({ id: this.invoice?.id }));
    }
  }

  // async cancelInvoicePayment(id: number) {
  //   const res = await firstValueFrom(
  //     this.dialog
  //       .open(ConfirmDialogComponent, {
  //         data: {
  //           title: 'Are you sure you want to cancel this invoice payment?',
  //           subtitle: '',
  //         },
  //       })
  //       .afterClosed(),
  //   );

  //   if (res) {
  //     this.store.dispatch(invoicePaymentActions.cancel({ id }));
  //   }
  // }

  async cancelRefundItem(id: number) {
    const res = await firstValueFrom(
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            title: 'Are you sure you want to cancel this refund item?',
            subtitle: '',
          },
        })
        .afterClosed(),
    );

    if (res) {
      this.store.dispatch(refundItemActions.cancel({ id }));
    }
  }

  isCustomerDraft() {
    return this.invoice?.status == INVOICE_STATUS.CUSTOMER_DRAFT;
  }

  hasPendingRefunds() {
    return this.invoice?.refundItems?.find(
      (refund) => refund.status == REFUND_ITEM_STATUS.STAFF_AUTO_DRAFT,
    );
  }

  canCancel() {
    return !this.invoice?.invoicePayments?.find(
      (payment) =>
        payment.status == INVOICE_PAYMENT_STATUS.PAID ||
        payment.status == INVOICE_PAYMENT_STATUS.PENDING,
    );
  }

  hasValidPayment() {
    return this.invoice?.status == INVOICE_STATUS.PAID;
  }

  isFinalized() {
    return (
      this.invoice?.status == INVOICE_STATUS.PAID && !this.hasPendingRefunds()
    );
  }

  invoicePaymentIsFinalized(
    invoicePayment: InvoicePaymentResourceTypeMappings['resourceWithRelationsT'],
  ) {
    return invoicePayment.status == INVOICE_PAYMENT_STATUS.PAID;
  }

  refundItemIsFinalized(
    refundItem: RefundItemResourceTypeMappings['resourceWithRelationsT'],
  ) {
    return refundItem.status == REFUND_ITEM_STATUS.REFUNDED;
  }

  getInvoiceItemPendingRefund(
    invoiceItem: InvoiceItemResourceTypeMappings['resourceWithRelationsT'],
  ) {
    return invoiceItem.refundItems?.find(
      (refund) => refund.status == REFUND_ITEM_STATUS.STAFF_AUTO_DRAFT,
    );
  }

  getInvoiceItemCompletedRefund(
    invoiceItem: InvoiceItemResourceTypeMappings['resourceWithRelationsT'],
  ) {
    return invoiceItem.refundItems?.find(
      (refund) => refund.status == REFUND_ITEM_STATUS.REFUNDED,
    );
  }

  getInvoicePaymentStatusLabel = (status: INVOICE_PAYMENT_STATUS) =>
    invoicePaymentStatusLabels[status];

  getInvoicePaymentStatusCSS = (status: INVOICE_PAYMENT_STATUS) => ({
    backgroundColor: invoicePaymentStatusColorMap[status][0],
    color: invoicePaymentStatusColorMap[status][1],
  });

  getRefundItemStatusLabel = (status: REFUND_ITEM_STATUS) =>
    refundItemStatusLabels[status];

  getRefundItemStatusCSS = (status: REFUND_ITEM_STATUS) => ({
    backgroundColor: refundItemStatusColorMap[status][0],
    color: refundItemStatusColorMap[status][1],
  });

  formatDateTime = formatDateTime;

  formatMoney = formatUSD;
}
