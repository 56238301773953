import { StaffProfileResourceTypeMappings } from '@aa/nest/resource';
import { createAction, props } from '@ngrx/store';
import { StaffProfileFormModel } from '../../forms/staff-profile-form-modal/staff-profile-form-modal.component';
import { createBaseResourceActions } from '../create-base-resource-actions';

// @aa:gen-ignore

const domainSlug = '[STAFF PROFILE]';
export const staffProfileActions = {
  ...createBaseResourceActions<StaffProfileResourceTypeMappings>(domainSlug),
  createStaffMemberWithUserAccount: createAction(
    `${domainSlug} Create Staff Member With User Account`,
    props<{
      data: StaffProfileFormModel & { email: string };
      andLoadToCurrent?: boolean;
      loadToCurrentInclude?: StaffProfileResourceTypeMappings['includeT'];
    }>(),
  ),
  updateStaffMemberWithUserAccount: createAction(
    `${domainSlug} Update Staff Member with User Account`,
    props<{
      id: StaffProfileResourceTypeMappings['resourcePrimaryKeyT'];
      data: StaffProfileFormModel & { email: string; userId: number };
    }>(),
  ),
};
