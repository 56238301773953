import { invoiceItemActions } from './invoice-item.actions';
import { CoreAppState } from '../core-app.state';
import {
  BaseResourceState,
  createBaseResourceReducer,
  initialResourceState,
} from '../create-base-resource-reducer';
import { InvoiceItemResourceTypeMappings } from '@aa/nest/resource';

export type InvoiceItemState =
  BaseResourceState<InvoiceItemResourceTypeMappings>;
export const initialInvoiceItemState: InvoiceItemState = initialResourceState;

export const invoiceItemReducer = createBaseResourceReducer<
  InvoiceItemResourceTypeMappings,
  InvoiceItemState
>(initialInvoiceItemState, invoiceItemActions);

export const selectInvoiceItemState = (state: CoreAppState) =>
  state.invoiceItem ?? initialInvoiceItemState;
