import { OfferedServiceResourceTypeMappings } from '@aa/nest/resource';
import { createAction, props } from '@ngrx/store';
import { createBaseResourceActions } from '../create-base-resource-actions';
import { Action } from 'rxjs/internal/scheduler/Action';

// @aa:gen-ignore

const domainSlug = '[OFFERED SERVICE]';
export const offeredServiceActions = {
  ...createBaseResourceActions<OfferedServiceResourceTypeMappings>(domainSlug),

  handleOrderLinkages: createAction(
    `${domainSlug} Handle Order Linkages`,
    props<{
      data: HandleOrderLinkagesDataPayload;
      forDraft?: boolean;
      noRefresh?: boolean;
    }>(),
  ),
  handleOrderCardLinkages: createAction(
    `${domainSlug} Handle Order Card Linkages`,
    props<{
      data: HandleOrderCardLinkagesDataPayload;
      forDraft?: boolean;
      chainedAction?: Action<any>;
    }>(),
  ),
};

export type HandleOrderLinkagesDataPayload = {
  orderId: number;
  linkages: {
    offeredServiceId: number;
    isDraft?: boolean;
  }[];
};
export type HandleOrderCardLinkagesDataPayload = {
  orderCardId: number;
  linkages: {
    id?: number;
    offeredServiceId: number;
    isDraft?: boolean;
  }[];
};
