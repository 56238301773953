import { AppBarComponent, authActions, selectAuth } from '@aa/angular/core';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  firstValueFrom,
  Observable,
} from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { isInStandaloneMode } from '@aa/ts/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { StaffAppState } from '../../state/index.reducers';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import '@khmyznikov/pwa-install';

const unprotectedRoutes: string[] = [
  'login',
  'forgot-password',
  'reset-password',
];

const hideLayoutRoutes: string[] = [
  'login',
  'forgot-password',
  'reset-password',
];

interface SidenavItem {
  key: string;
  label: string;
  icon?: string;
  routerPath?: string;
  children?: SidenavItem[];
}

@Component({
  selector: 'aa-staff-app-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    AppBarComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './staff-app-layout.component.html',
  styleUrl: './staff-app-layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaffAppLayoutComponent {
  @ViewChild('pwaInstallPrompt') pwaInstallPrompt!: ElementRef;

  sidenavIsOpen$: Observable<boolean | undefined>;
  hideLayout$ = new BehaviorSubject(false);
  sidenavItems: SidenavItem[] = [
    // {
    //   key: 'home',
    //   label: 'Home',
    //   icon: 'home',
    //   routerPath: '/',
    // },
    {
      key: 'orders',
      label: 'Orders',
      icon: 'view_list',
      routerPath: '/orders',
    },
    {
      key: 'customers',
      label: 'Customers',
      icon: 'face',
      routerPath: '/customers',
    },
    {
      key: 'configurables',
      label: 'Configurables',
      icon: 'manufacturing',
      children: [
        {
          key: 'offered-services',
          label: 'Offered Services',
          icon: 'conveyor_belt',
          routerPath: 'offered-services',
        },
        {
          key: 'order-statuses',
          label: 'Order Statuses',
          icon: 'step',
          routerPath: '/order-statuses',
        },
        {
          key: 'order-sources',
          label: 'Order Sources',
          icon: 'move_location',
          routerPath: '/order-sources',
        },
        {
          key: 'order-card-attribute-types',
          label: 'Card Attribute Types',
          icon: 'description',
          routerPath: 'order-card-attribute-types',
        },
      ],
    },
    {
      key: 'admin',
      label: 'Admin',
      icon: 'admin_panel_settings',
      children: [
        {
          key: 'staff',
          label: 'Staff',
          icon: 'badge',
          routerPath: '/staff',
        },
        {
          key: 'actionLogs',
          label: 'Action Logs',
          icon: 'manage_search',
          routerPath: '/action-logs',
        },
      ],
    },
  ];
  expandedItems$ = new BehaviorSubject<string[]>([]);

  constructor(
    private readonly store: Store<StaffAppState>,
    private readonly router: Router,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly snackBar: MatSnackBar,
  ) {
    this.sidenavIsOpen$ = this.store
      .select((s) => s.sidenavIsOpen)
      .pipe(takeUntilDestroyed());
    combineLatest([
      this.store.select((s) => selectAuth(s).user),
      this.router.events.pipe(
        filter((e) => e instanceof NavigationEnd),
        takeUntilDestroyed(),
      ),
    ]).subscribe(([user, routeEvent]) => {
      const urlSlug = routeEvent.url.split('/')[1];
      if (hideLayoutRoutes.includes(urlSlug)) {
        this.hideLayout$.next(true);
      } else {
        this.hideLayout$.next(false);
      }

      if (!unprotectedRoutes.includes(urlSlug) && !user) {
        this.store.dispatch(
          authActions.setOriginalPath({ path: routeEvent.url }),
        );
        this.router.navigate(['login']);
      }
    });
  }

  ngAfterViewInit() {
    const initiallyLoaded = localStorage.getItem('initiallyLoaded');
    if (initiallyLoaded != 'true') {
      // this.helpTooltip.show();
      this.showInstallPrompt();
      localStorage.setItem('initiallyLoaded', 'true');
    }
  }

  showInstallPrompt() {
    if (!isInStandaloneMode())
      (this.pwaInstallPrompt?.nativeElement as any).showDialog();
  }

  async toggleExpandedItem(sidenavItem: SidenavItem) {
    if (!sidenavItem.children) return;
    const expandedItems = await firstValueFrom(this.expandedItems$);
    if (expandedItems.includes(sidenavItem.key))
      this.expandedItems$.next([
        ...expandedItems.filter((i) => i != sidenavItem.key),
      ]);
    else this.expandedItems$.next([...expandedItems, sidenavItem.key]);
  }
}
