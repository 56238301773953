import { InvoiceResourceTypeMappings } from '@aa/nest/resource';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { formatDateTime } from '@aa/ts/common';
import {
  DataTableComponent,
  DataTableColumnConfig,
} from '../../components/data-table/data-table.component';
import {
  BaseResourceListViewComponent,
  baseResourceListViewImports,
} from '../base-resource-list-view/base-resource-list-view.component';
import { InvoiceFormModalComponent } from '../../forms/invoice-form-modal/invoice-form-modal.component';
import { invoiceActions } from '../../state/invoice/invoice.actions';
import { selectInvoiceState } from '../../state/invoice/invoice.reducer';

@Component({
  selector: 'aa-invoices-list-view',
  standalone: true,
  imports: [...baseResourceListViewImports],
  templateUrl:
    '../../../../../core/src/lib/views/base-resource-list-view/base-resource-list-view.component.html',
  styleUrl:
    '../../../../../core/src/lib/views/base-resource-list-view/base-resource-list-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicesListViewComponent extends BaseResourceListViewComponent<InvoiceResourceTypeMappings> {
  title = 'Invoices';
  columns = [
    {
      key: 'id',
      label: 'ID',
      minWidth: '3rem',
    },
    {
      key: 'amount',
      label: 'Amount',
    },
    {
      key: 'status',
      label: 'Status',
    },
    {
      key: 'createdAt',
      label: 'Created At',
      accessor: (row) => formatDateTime(row.createdAt),
      minWidth: '15rem',
    },
    {
      key: 'updatedAt',
      label: 'Updated At',
      accessor: (row) => formatDateTime(row.updatedAt),
      minWidth: '15rem',
    },
  ] as DataTableColumnConfig<
    InvoiceResourceTypeMappings['resourceWithRelationsT']
  >[];

  stateSelector = selectInvoiceState;
  loadAction = invoiceActions.loadItems({
    query: {
      pageSize: this.initialPageSize,
    },
  });
  actions = invoiceActions;

  createFormModalTypeGetter = () => InvoiceFormModalComponent;
  updateFormModalTypeGetter = () => InvoiceFormModalComponent;
}
