<aa-view-bar
  title="Edit Draft"
  [rightText]="(loading$ | async) ? 'Saving' : 'Autosaved'"
  backPath="/"></aa-view-bar>

<div class="customer-view-container v-scrollable">
  <div class="customer-view-sections">
    <div class="section">
      <h2>
        Cards In Submission ({{ (order$ | async)?.orderCards?.length ?? 0 }})
      </h2>
      <div class="cards">
        @for (card of (order$ | async)?.orderCards ?? []; track card.id) {
          <button
            class="card-tile"
            [routerLink]="'cards/' + card.id"
            [ngStyle]="{
              'background-image': 'url(' + card.frontImageURL + ')'
            }"></button>
        }
        <button
          class="card-tile"
          [routerLink]="'/draft-editor/' + (order$ | async)?.id + '/new-card'">
          <mat-icon>Add</mat-icon>
          <p>Add</p>
        </button>
      </div>
    </div>

    <div class="section">
      <h2>Draft Details</h2>
      <form [formGroup]="form">
        <formly-form [form]="form" [fields]="fields" [model]="model">
        </formly-form>

        <!-- <p class="error">{{ error$ | async }}</p> -->
      </form>
    </div>
  </div>
</div>

<div class="customer-view-actions">
  <button mat-flat-button color="primary" routerLink="review-cards">
    Review Cards
  </button>
</div>
