import { OfferedServiceResourceTypeMappings } from '@aa/nest/resource';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  BaseFormModalComponent,
  baseFormModalImports,
  FormModalData,
} from '../../components/base-form-modal/base-form-modal.component';
import { CoreAppState } from '../../state/core-app.state';
import { offeredServiceActions } from '../../state/offered-service/offered-service.actions';

// @aa:gen-ignore

export type OfferedServiceFormModel = Partial<
  OfferedServiceResourceTypeMappings['createDTO']
>;
export type OfferedServiceFormModalData =
  FormModalData<OfferedServiceFormModel>;

@Component({
  selector: 'aa-offered-service-form-modal',
  standalone: true,
  imports: [...baseFormModalImports],
  templateUrl:
    '../../components/base-form-modal/base-form-modal.component.html',
  styleUrl: '../../components/base-form-modal/base-form-modal.component.scss',
})
export class OfferedServiceFormModalComponent extends BaseFormModalComponent<
  OfferedServiceFormModel,
  OfferedServiceFormModalData
> {
  title = 'Offered Service';
  fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      props: {
        label: 'Name',
        type: 'text',
        required: true,
      },
    },
    {
      fieldGroupClassName: 'flex-row-field-group',
      fieldGroup: [
        {
          key: 'hexColor',
          type: 'input',
          props: {
            label: 'Hex Color',
            type: 'text',
            required: true,
          },
        },
        {
          key: 'hexColor',
          type: 'input',
          props: {
            label: 'Color Picker',
            type: 'color',
            required: true,
          },
        },
      ],
    },
    {
      key: 'stripeProductId',
      type: 'input',
      props: {
        label: 'Stripe Product Id',
      },
    },
  ];

  createAction = offeredServiceActions.createItem;
  updateAction = offeredServiceActions.updateItem;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected override readonly data: OfferedServiceFormModalData,
    protected override readonly dialogRef: MatDialogRef<OfferedServiceFormModalComponent>,
    protected override readonly store: Store<CoreAppState>,
  ) {
    super(data, dialogRef, store);
  }
}
