import { Actions, createEffect, ofType } from '@ngrx/effects';
import { authActions } from './auth.actions';
import { AuthResponse } from '@aa/nest/auth';
import { AuthService } from '../../services/auth.service';
import { CoreAppState } from '../core-app.state';
import { first, map, switchMap, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { PaymentService } from '../../services/payment.service';
import { Router } from '@angular/router';
import { selectAuth } from './auth.reducer';
import { Store } from '@ngrx/store';
import { User } from '@prisma/client';

@Injectable()
export class AuthEffects {
  loginCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loginCustomer),
      switchMap((payload: { email: string; password: string }) =>
        this.store
          .select((s) => selectAuth(s))
          .pipe(
            first(),
            switchMap((authState) =>
              this.authService.login(payload.email, payload.password).pipe(
                map((userAndRefreshToken) =>
                  (userAndRefreshToken as { error: any }).error
                    ? authActions.setError({
                        message: (userAndRefreshToken as { error: any }).error,
                      })
                    : !(userAndRefreshToken as AuthResponse).data.user
                          ?.customerProfile
                      ? authActions.setError({
                          message:
                            'User account is not associated with a Customer Profile',
                        })
                      : authActions.setUser({
                          user: (userAndRefreshToken as AuthResponse).data.user,
                          refreshToken: (userAndRefreshToken as AuthResponse)
                            .data.refreshToken,
                        }),
                ),
                tap((r) => {
                  if (
                    r.type == authActions.setUser.type &&
                    authState.originalPath
                  )
                    this.router.navigateByUrl(authState.originalPath);
                }),
              ),
            ),
          ),
      ),
    ),
  );

  loginStaff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loginStaff),
      switchMap((payload: { email: string; password: string }) =>
        this.store
          .select((s) => selectAuth(s))
          .pipe(
            first(),
            switchMap((authState) =>
              this.authService.login(payload.email, payload.password).pipe(
                map((userAndRefreshToken) =>
                  (userAndRefreshToken as { error: any }).error
                    ? authActions.setError({
                        message: (userAndRefreshToken as { error: any }).error,
                      })
                    : !(userAndRefreshToken as AuthResponse).data.user
                          ?.staffProfile
                      ? authActions.setError({
                          message:
                            'User account is not associated with a Staff Profile',
                        })
                      : authActions.setUser({
                          user: (userAndRefreshToken as AuthResponse).data.user,
                          refreshToken: (userAndRefreshToken as AuthResponse)
                            .data.refreshToken,
                        }),
                ),
                tap((r) => {
                  if (
                    r.type == authActions.setUser.type &&
                    authState.originalPath
                  )
                    this.router.navigateByUrl(authState.originalPath);
                }),
              ),
            ),
          ),
      ),
    ),
  );

  registerCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.registerCustomer),
      switchMap(
        (payload: {
          email: string;
          password: string;
          firstName: string;
          lastName: string;
          addressLine1: string;
          addressLine2: string;
          city: string;
          state: string;
          zipCode: string;
          country: string;
        }) =>
          this.store
            .select((s) => selectAuth(s))
            .pipe(
              first(),
              switchMap((authState) =>
                this.authService
                  .registerCustomer(
                    payload.email,
                    payload.password,
                    payload.firstName,
                    payload.lastName,
                    payload.addressLine1,
                    payload.addressLine2,
                    payload.city,
                    payload.state,
                    payload.zipCode,
                    payload.country,
                  )
                  .pipe(
                    map((userAndRefreshToken) =>
                      (userAndRefreshToken as { error: any }).error
                        ? authActions.setError({
                            message: (userAndRefreshToken as { error: any })
                              .error,
                          })
                        : !(userAndRefreshToken as AuthResponse).data.user
                              ?.customerProfile
                          ? authActions.setError({
                              message:
                                'User account is not associated with a Customer Profile',
                            })
                          : authActions.setUser({
                              user: (userAndRefreshToken as AuthResponse).data
                                .user,
                              refreshToken: (
                                userAndRefreshToken as AuthResponse
                              ).data.refreshToken,
                            }),
                    ),
                    tap((r) => {
                      if (
                        r.type == authActions.setUser.type &&
                        authState.originalPath
                      )
                        this.router.navigateByUrl(authState.originalPath);
                    }),
                  ),
              ),
            ),
      ),
    ),
  );

  updateProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.updateProfile),
      switchMap(
        (payload: {
          firstName?: string;
          lastName?: string;
          email?: string;
          receiveEmailNotifications?: boolean;
        }) =>
          this.store
            .select((s) => selectAuth(s).user?.id)
            .pipe(
              switchMap((id?: number) =>
                this.authService
                  .updateProfile(
                    id!,
                    payload.firstName,
                    payload.lastName,
                    payload.email,
                    payload.receiveEmailNotifications,
                  )
                  .pipe(
                    map((res: User | { error: any }) =>
                      (res as { error: any }).error
                        ? authActions.setError({
                            message: (res as { error: any }).error,
                          })
                        : authActions.setUser({
                            user: res as User,
                          }),
                    ),
                    tap((action: any) => {
                      if (action.type == authActions.setUser.type) {
                        this.router.navigate(['']);
                      }
                    }),
                  ),
              ),
            ),
      ),
    ),
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.updateProfile),
      tap(() => {
        window.location.reload();
      }),
      map(() => ({ type: 'NO OP' }) as any),
    ),
  );

  checkPaymentMethodStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.checkPaymentMethodStatus),
      switchMap((payload) =>
        this.paymentService
          .getPaymentMethodStatus()
          .pipe(
            map((res) =>
              authActions.setPaymentMethodStatus({ setup: res.setup }),
            ),
          ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly paymentService: PaymentService,
    private readonly store: Store<CoreAppState>,
    private readonly router: Router,
  ) {}
}
