import { invoicePaymentActions } from './invoice-payment.actions';
import { CoreAppState } from '../core-app.state';
import {
  BaseResourceState,
  createBaseResourceReducer,
  initialResourceState,
} from '../create-base-resource-reducer';
import { InvoicePaymentResourceTypeMappings } from '@aa/nest/resource';

export type InvoicePaymentState =
  BaseResourceState<InvoicePaymentResourceTypeMappings>;
export const initialInvoicePaymentState: InvoicePaymentState =
  initialResourceState;

export const invoicePaymentReducer = createBaseResourceReducer<
  InvoicePaymentResourceTypeMappings,
  InvoicePaymentState
>(initialInvoicePaymentState, invoicePaymentActions);

export const selectInvoicePaymentState = (state: CoreAppState) =>
  state.invoicePayment ?? initialInvoicePaymentState;
