import {
  BaseFormModalComponent,
  baseFormModalImports,
  BaseResourceFormModelOptionsMappers,
  FormModalData,
} from '../../components/base-form-modal/base-form-modal.component';
import { Component, Inject } from '@angular/core';
import { CoreAppState } from '../../state/core-app.state';
import { firstValueFrom, Observable } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { invoiceItemActions } from '../../state/invoice-item/invoice-item.actions';
import {
  InvoiceItemResourceTypeMappings,
  OrderCardResourceTypeMappings,
} from '@aa/nest/resource';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { offeredServiceActions } from '../../state/offered-service/offered-service.actions';
import { orderCardActions } from '../../state/order-card/order-card.actions';
import { selectOfferedServiceState } from '../../state/offered-service/offered-service.reducer';
import { selectOrderCardState } from '../../state/order-card/order-card.reducer';
import { Store } from '@ngrx/store';
import { OrderServiceLinkageService } from '../../services/order-service-linkage.service';
import { OrderCardServiceLinkageService } from '../../services/order-card-service-linkage.service';

// @aa:gen-ignore

export type InvoiceItemFormModel = Partial<
  InvoiceItemResourceTypeMappings['createDTO']
> & {
  orderCardId: number;
};
export type InvoiceItemFormModalData = FormModalData<InvoiceItemFormModel>;

@Component({
  selector: 'aa-invoice-item-form-modal',
  standalone: true,
  imports: [...baseFormModalImports],
  templateUrl:
    '../../components/base-form-modal/base-form-modal.component.html',
  styleUrl: '../../components/base-form-modal/base-form-modal.component.scss',
})
export class InvoiceItemFormModalComponent extends BaseFormModalComponent<
  InvoiceItemFormModel,
  InvoiceItemFormModalData
> {
  title = 'Invoice Item';
  fields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'flex-row-field-group',
      fieldGroup: [
        {
          key: 'orderCardId',
          type: 'select',
          props: {
            label: 'Order Card',
            required: false,
            options: [],
          },
          hooks: {
            onInit: (field) => {
              field.formControl?.valueChanges.subscribe(async (v) => {
                this.store.dispatch(
                  offeredServiceActions.loadItems({
                    query: {
                      where: v
                        ? {
                            orderCardServiceLinkages: {
                              some: {
                                orderCardId: v,
                              },
                            },
                          }
                        : {
                            level: 0,
                          },
                    },
                  }),
                );
                if (field.props?.options && !this.data.meta?.linkageOnly) {
                  const label = (
                    await firstValueFrom(
                      field.props.options as Observable<
                        { label: string; value: number }[]
                      >,
                    )
                  ).find((o) => o.value == v)?.label;
                  if (label) {
                    // field.form?.get('description')?.setValue(`For ${label}`);
                  }
                }
              });
            },
          },
        },
        {
          key: 'offeredServiceId',
          type: 'select',
          props: {
            label: 'Offered Service',
            required: true,
            options: [],
          },
          hooks: {
            onInit: (field) => {
              field.formControl?.valueChanges.subscribe(async (v) => {
                if (field.props?.options && !this.data.meta?.linkageOnly) {
                  const label = (
                    await firstValueFrom(
                      field.props.options as Observable<
                        { label: string; value: number; meta: any }[]
                      >,
                    )
                  ).find((o) => o.value == v)?.label;
                  if (label) {
                    field.form?.get('title')?.setValue(label);
                  }
                }
              });
            },
          },
        },
      ],
    },
    {
      key: 'title',
      type: 'input',
      props: {
        label: 'Title',
        type: 'text',
        required: true,
      },
    },
    {
      key: 'description',
      type: 'input',
      props: {
        label: 'Description',
        type: 'text',
        required: false,
      },
    },
    {
      fieldGroupClassName: 'flex-row-field-group',
      fieldGroup: [
        {
          key: 'costPerUnit',
          type: 'input',
          props: {
            label: 'Cost Per Unit',
            type: 'number',
            required: true,
          },
        },
        {
          key: 'quantity',
          type: 'input',
          defaultValue: 1,
          props: {
            label: 'Quantity',
            type: 'number',
            required: false,
            min: 1,
          },
        },
      ],
    },
  ];

  override dontStripKeys: (keyof InvoiceItemFormModel)[] = [
    'invoiceId',
    'title',
    'description',
  ];

  // createAction = invoiceItemActions.createItem;
  createAction = undefined;
  // updateAction = invoiceItemActions.updateItem;
  updateAction = undefined;

  override optionsMappers: BaseResourceFormModelOptionsMappers = {
    // invoiceId: {
    //   loadAction: invoiceActions.loadItems({}),
    //   stateSelector: selectInvoiceState,
    // },
    offeredServiceId: {
      loadAction: offeredServiceActions.loadItems({
        query: {
          where: {
            level: 0,
          },
        },
      }),
      stateSelector: selectOfferedServiceState,
    },
    orderCardId: {
      // loadAction: orderCardActions.loadItems({}),
      stateSelector: selectOrderCardState,
      getLabel: (
        item: OrderCardResourceTypeMappings['resourceWithRelationsT'],
      ) => `${item.label} (#C_${item.id})`,
    },
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected override readonly data: InvoiceItemFormModalData,
    protected override readonly dialogRef: MatDialogRef<InvoiceItemFormModalComponent>,
    protected override readonly store: Store<CoreAppState>,
    private readonly orderServiceLinkageService: OrderServiceLinkageService,
    private readonly orderCardServiceLinkageService: OrderCardServiceLinkageService,
  ) {
    super(data, dialogRef, store);

    if (data.meta.linkageOnly) {
      // don't allow price editing
      const fields = [...this.fields];

      fields[3].fieldGroup![0].props!.disabled = true;
      fields[3].fieldGroup![1].props!.disabled = true;
      this.fields = [...fields];
    }

    const orderId = this.data.meta['orderId'];
    this.store.dispatch(
      orderCardActions.loadItems({
        query: {
          where: {
            orderId,
          },
          include: {
            orderCardAttributes: {
              include: {
                orderCardAttributeType: true,
              },
            },
          },
        },
      }),
    );
  }

  override onSubmit = async (stripped: InvoiceItemFormModel) => {
    const orderCardId = stripped.orderCardId;
    const orderId = this.data.meta['orderId'];
    delete (stripped as any).orderCardId;

    if (this.data.mode == 'create') {
      this.store.dispatch(
        invoiceItemActions.createItem({
          data: stripped as any as InvoiceItemResourceTypeMappings['createDTO'],
          orderServiceLinkageId:
            !stripped.orderCardId && orderId
              ? (
                  await firstValueFrom(
                    this.orderServiceLinkageService.getMany({
                      where: {
                        offeredServiceId: this.model.offeredServiceId,
                        orderId: orderId,
                      },
                    }),
                  )
                )?.data?.[0]?.id
              : undefined,
          orderCardServiceLinkageId: orderCardId
            ? (
                await firstValueFrom(
                  this.orderCardServiceLinkageService.getMany({
                    where: {
                      offeredServiceId: this.model.offeredServiceId,
                      orderCardId: orderCardId,
                    },
                  }),
                )
              )?.data?.[0]?.id
            : undefined,
          ...(this.extraCreateActionData ?? {}),
        }),
      );
    } else if (this.data.mode == 'update') {
      this.store.dispatch(
        invoiceItemActions.updateItem({
          id: (this.model as any)[this.primaryKeyField],
          data: stripped as any as InvoiceItemResourceTypeMappings['createDTO'],
          orderServiceLinkageId:
            !stripped.orderCardId && orderId
              ? (
                  await firstValueFrom(
                    this.orderServiceLinkageService.getMany({
                      where: {
                        offeredServiceId: this.model.offeredServiceId,
                        orderId: orderId,
                      },
                    }),
                  )
                )?.data?.[0]?.id
              : undefined,
          orderCardServiceLinkageId: orderCardId
            ? (
                await firstValueFrom(
                  this.orderCardServiceLinkageService.getMany({
                    where: {
                      offeredServiceId: this.model.offeredServiceId,
                      orderCardId: orderCardId,
                    },
                  }),
                )
              )?.data?.[0]?.id
            : undefined,
          ...(this.extraCreateActionData ?? {}),
        }),
      );
    }
  };
}
