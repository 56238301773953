<div class="container">
  <h1>Setup Payment Method</h1>
  <p>
    We use stripe to handle customer payment processing, please setup your
    payment method before continuing.
  </p>

  <button mat-flat-button color="primary" (click)="setupPaymentMethod()">
    Setup
  </button>
</div>
