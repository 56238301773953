import {
  invoiceActions,
  InvoiceHistoryComponent,
  selectInvoiceState,
} from '@aa/angular/core';
import {
  INVOICE_STATUS,
  InvoiceResourceTypeMappings,
} from '@aa/nest/resource/objects';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, map, firstValueFrom } from 'rxjs';
import { StaffAppState } from '../../state/index.reducers';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'aa-order-invoices-tab',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,

    InvoiceHistoryComponent,
  ],
  templateUrl: './order-invoices-tab.component.html',
  styleUrl: './order-invoices-tab.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderInvoicesTabComponent {
  orderId$: Observable<number>;
  invoices$: Observable<
    InvoiceResourceTypeMappings['resourceWithRelationsT'][]
  >;
  invoicesLoading$: Observable<boolean>;

  constructor(
    private readonly store: Store<StaffAppState>,
    private readonly route: ActivatedRoute,
  ) {
    this.orderId$ = this.route.paramMap.pipe(
      map((paramMap) => parseInt(paramMap.get('id')!)),
      takeUntilDestroyed(),
    );
    this.invoices$ = this.store
      .select((s) => selectInvoiceState(s).items)
      .pipe(takeUntilDestroyed());
    this.invoicesLoading$ = this.store
      .select((s) => selectInvoiceState(s).itemsLoading)
      .pipe(takeUntilDestroyed());

    this.orderId$.subscribe((orderId) => {});
  }

  async createNewInvoiceDraft() {
    this.store.dispatch(
      invoiceActions.createItem({
        data: {
          amount: 0,
          orderId: await firstValueFrom(this.orderId$),
          status: INVOICE_STATUS.STAFF_DRAFT,
        },
      }),
    );
  }

  async adjustInvoicingForChanges() {
    this.store.dispatch(
      invoiceActions.autoAdjustForOrder({
        id: await firstValueFrom(this.orderId$),
      }),
    );
  }

  cancelRefund(refundId: number) {}

  cancelPayment(paymentId: number) {}
}
