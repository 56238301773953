import { UserRoleAssignment, Prisma } from '@prisma/client';
import { CreateUserRoleAssignmentDTO } from './dto/create-user-role-assignment.dto';
import { UpdateUserRoleAssignmentDTO } from './dto/update-user-role-assignment.dto';
import { QueryUserRoleAssignmentDTO } from './dto/query-user-role-assignment.dto';

import {
  BaseResourceTypeMappings,
  MapPossibleIncludesToRequiredDepth,
} from '@aa/nest/common';
import { XOR } from '@aa/ts/common';

export type UserRoleAssignmentResourceTypeMappings = BaseResourceTypeMappings<
  UserRoleAssignment,
  UserRoleAssignment &
    Exclude<
      Partial<
        Prisma.UserRoleAssignmentGetPayload<{
          include: MapPossibleIncludesToRequiredDepth<
            Prisma.UserRoleAssignmentInclude,
            4
          >;
        }>
      >,
      UserRoleAssignment
    >,
  {
    userId: number;
    userRoleId: number;
  },
  XOR<
    Prisma.UserRoleAssignmentCreateInput,
    Prisma.UserRoleAssignmentUncheckedCreateInput
  >,
  XOR<
    Prisma.UserRoleAssignmentUpdateInput,
    Prisma.UserRoleAssignmentUncheckedUpdateInput
  >,
  Prisma.UserRoleAssignmentWhereInput,
  Prisma.UserRoleAssignmentWhereUniqueInput,
  Prisma.UserRoleAssignmentInclude,
  Prisma.UserRoleAssignmentOrderByWithRelationInput,
  CreateUserRoleAssignmentDTO,
  UpdateUserRoleAssignmentDTO,
  QueryUserRoleAssignmentDTO
>;

export const userRoleAssignmentCompositeKeyOrder: (keyof UserRoleAssignmentResourceTypeMappings['resourceT'])[] =
  ['userId', 'userRoleId'];
