import { CoreAppState } from '../state/core-app.state';
import { BaseResourceService } from './base-resource.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrderCardServiceLinkage } from '@prisma/client';
import { OrderCardServiceLinkageResourceTypeMappings } from '@aa/nest/resource';
import { orderCardServiceLinkageCompositeKeyOrder } from '@aa/nest/resource/objects';

@Injectable()
export class OrderCardServiceLinkageService extends BaseResourceService<OrderCardServiceLinkageResourceTypeMappings> {
  constructor(
    protected override readonly http: HttpClient,
    protected override readonly store: Store<CoreAppState>,
  ) {
    super(
      http,
      store,
      'order-card-service-linkages',
      undefined,
      orderCardServiceLinkageCompositeKeyOrder,
    );
  }
}
