import { refundItemActions } from './refund-item.actions';
import { CoreAppState } from '../core-app.state';
import {
  BaseResourceState,
  createBaseResourceReducer,
  initialResourceState,
} from '../create-base-resource-reducer';
import { RefundItemResourceTypeMappings } from '@aa/nest/resource';

export type RefundItemState = BaseResourceState<RefundItemResourceTypeMappings>;
export const initialRefundItemState: RefundItemState = initialResourceState;

export const refundItemReducer = createBaseResourceReducer<
  RefundItemResourceTypeMappings,
  RefundItemState
>(initialRefundItemState, refundItemActions);

export const selectRefundItemState = (state: CoreAppState) =>
  state.refundItem ?? initialRefundItemState;
