<div class="custom-form-field">
  <div class="header">
    <p class="label">
      {{ props.label }}

      @if (props.required) {
        *
      }
    </p>

    <!-- <mat-slide-toggle
      [disabled]="$any(props)?.disableDraftToggle"
      [checked]="isDraft$ | async"
      (toggleChange)="toggleIsDraft()"
      color="primary"
      ><strong>Draft</strong></mat-slide-toggle
    > -->

    <mat-slide-toggle
      [checked]="isDraft$ | async"
      (toggleChange)="toggleIsDraft()"
      color="primary"
      [disabled]="$any(props)?.disableDraftToggle || props.disabled"
      ><strong>Draft</strong></mat-slide-toggle
    >
  </div>

  @for (option of fieldOptions$ | async; track $index) {
    <div class="option">
      @if ($any(props).multiple) {
        <mat-checkbox
          [disabled]="props.disabled || !(isDraft$ | async)"
          (change)="onOptionClicked(option.value)"
          [checked]="
            getOptionValueChecked(option.value) | async
          "></mat-checkbox>
      } @else {
        <mat-radio-button
          [disabled]="props.disabled || !(isDraft$ | async)"
          (change)="onOptionClicked(option.value)"
          [checked]="
            getOptionValueChecked(option.value) | async
          "></mat-radio-button>
      }
      <p>{{ option.label }}</p>
    </div>
  }
</div>
