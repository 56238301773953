import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  AppBarComponent,
  authActions,
  AuthService,
  HelpModalComponent,
  HelpModalData,
  selectAuth,
} from '@aa/angular/core';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  firstValueFrom,
  map,
} from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { CustomerAppState } from '../../state/index.reducers';
import { isInStandaloneMode } from '@aa/ts/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { PaymentMethodSetupPopupComponent } from '../payment-method-setup-popup/payment-method-setup-popup.component';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import '@khmyznikov/pwa-install';

const unprotectedRoutes: string[] = [
  'register',
  'login',
  'forgot-password',
  'reset-password',
];

const hideLayoutRoutes: string[] = [
  'register',
  'login',
  'forgot-password',
  'reset-password',
];

const helpImageMapping: Record<string, string> = {
  '': 'customer-app-home-screen-help-modal-image.png',
  'draft-editor': 'customer-edit-draft-screen-help-modal-image.png',
  'new-card': 'customer-new-card-screen-help-modal-image.png',
};

@Component({
  selector: 'aa-customer-app-layout',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    AppBarComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './customer-app-layout.component.html',
  styleUrl: './customer-app-layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerAppLayoutComponent implements AfterViewInit {
  hideLayout$ = new BehaviorSubject(false);
  currentHelpImage$ = new BehaviorSubject<string | undefined>(undefined);
  showHelpButton$ = this.currentHelpImage$.pipe(map((v) => !!v));

  @ViewChild('pwaInstallPrompt') pwaInstallPrompt!: ElementRef;
  @ViewChild('helpTooltip') helpTooltip!: MatTooltip;

  constructor(
    private readonly store: Store<CustomerAppState>,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly authService: AuthService,
  ) {
    combineLatest([
      this.store.select((s) => selectAuth(s).user),
      this.router.events.pipe(
        filter((e) => e instanceof NavigationEnd),
        takeUntilDestroyed(),
      ),
    ]).subscribe(([user, routeEvent]) => {
      const urlSlug = routeEvent.url.split('/')[1];
      if (hideLayoutRoutes.includes(urlSlug)) {
        this.hideLayout$.next(true);
      } else {
        this.hideLayout$.next(false);
      }

      if (!unprotectedRoutes.includes(urlSlug) && !user) {
        this.store.dispatch(
          authActions.setOriginalPath({ path: routeEvent.url }),
        );
        this.router.navigate(['login']);
      }

      if (helpImageMapping[urlSlug]) {
        this.currentHelpImage$.next(
          'assets/help-images/' + helpImageMapping[urlSlug],
        );
      } else {
        this.currentHelpImage$.next(undefined);
      }
    });

    this.store
      .select((s) => selectAuth(s).user)
      .subscribe((user) => {
        if (user?.customerProfile) {
          this.store.dispatch(authActions.checkPaymentMethodStatus());
        }
      });

    this.store
      .select((s) => selectAuth(s).paymentMethodSetup)
      .pipe(takeUntilDestroyed())
      .subscribe(async (setup) => {
        console.log('SETUP', setup);
        if (setup == false) {
          const authState = await firstValueFrom(this.store.select(selectAuth));
          const layoutHidden = await firstValueFrom(this.hideLayout$);
          if (authState.user?.customerProfile && !layoutHidden) {
            // prompt for payment method setup
            this.dialog
              .open(PaymentMethodSetupPopupComponent, {
                disableClose: true,
              })
              .afterClosed();
          }
        }
      });
  }

  ngAfterViewInit() {
    const initiallyLoaded = localStorage.getItem('initiallyLoaded');
    if (initiallyLoaded != 'true') {
      // this.helpTooltip.show();
      this.showInstallPrompt();
      localStorage.setItem('initiallyLoaded', 'true');
    }
  }

  showInstallPrompt() {
    if (!isInStandaloneMode())
      (this.pwaInstallPrompt?.nativeElement as any).showDialog();
  }

  async showHelp() {
    const imagePath = await firstValueFrom(this.currentHelpImage$);

    this.dialog.open(HelpModalComponent, {
      data: {
        imagePath,
      } as HelpModalData,
      maxWidth: 'calc(100vw - 2rem)',
      maxHeight: 'calc(100vh - 2rem)',
      autoFocus: false,
    });
  }
}
