import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BaseResourceEffects } from '../base-resource.effects';
import { catchError, map, mergeMap, of, switchMap, take } from 'rxjs';
import { CoreAppState } from '../core-app.state';
import { Injectable } from '@angular/core';
import { invoiceItemActions } from './invoice-item.actions';
import { InvoiceItemResourceTypeMappings } from '@aa/nest/resource';
import { InvoiceItemService } from '../../services/invoice-item.service';
import { PaymentService } from '../../services/payment.service';
import { query } from '@angular/animations';
import { selectInvoiceItemState } from './invoice-item.reducer';
import { Store } from '@ngrx/store';
import { OrderCardServiceLinkageService } from '../../services/order-card-service-linkage.service';
import { OrderServiceLinkageService } from '../../services/order-service-linkage.service';

// @aa:gen-ignore

@Injectable()
export class InvoiceItemEffects extends BaseResourceEffects<InvoiceItemResourceTypeMappings> {
  override createItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceItemActions.createItem),
      mergeMap((payload) =>
        this.store
          .select((s) => this.stateSelector(s).currentQuery)
          .pipe(
            take(1),
            switchMap((currentQuery) =>
              this.service.create(payload.data).pipe(
                switchMap((res) =>
                  (payload.orderCardServiceLinkageId
                    ? this.orderCardServiceLinkageService.update(
                        payload.orderCardServiceLinkageId,
                        {
                          invoiceItemId: res.id,
                        },
                      )
                    : payload.orderServiceLinkageId
                      ? this.orderServiceLinkageService.update(
                          payload.orderServiceLinkageId,
                          {
                            invoiceItemId: res.id,
                          },
                        )
                      : of({})
                  ).pipe(
                    switchMap((linkageUpdateRes) =>
                      this.paymentService
                        .updateInvoiceTotal(res.invoiceId)
                        .pipe(
                          map((updateRes) =>
                            payload.andLoadToCurrent
                              ? this.itemActions.loadItem({
                                  id: res.id,
                                  include: payload.loadToCurrentInclude ?? {},
                                })
                              : this.itemActions.loadItems({
                                  query: currentQuery as any,
                                }),
                          ),
                        ),
                    ),
                  ),
                ),
                catchError((err) =>
                  of(
                    this.itemActions.setError({
                      error:
                        err.error?.message?.toString() ??
                        err.message?.toString() ??
                        err.toString(),
                    }),
                  ),
                ),
              ),
            ),
          ),
      ),
    ),
  );

  override updateItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceItemActions.updateItem),
      mergeMap((payload) =>
        this.store
          .select((s) => this.stateSelector(s).currentQuery)
          .pipe(
            take(1),
            switchMap((currentQuery) =>
              this.service.update(payload.id, payload.data).pipe(
                switchMap((res) =>
                  (payload.orderCardServiceLinkageId
                    ? this.orderCardServiceLinkageService.update(
                        payload.orderCardServiceLinkageId,
                        {
                          invoiceItemId: res.id,
                        },
                      )
                    : payload.orderServiceLinkageId
                      ? this.orderServiceLinkageService.update(
                          payload.orderServiceLinkageId,
                          {
                            invoiceItemId: res.id,
                          },
                        )
                      : of({})
                  ).pipe(
                    switchMap((linkageUpdateRes) =>
                      this.paymentService
                        .updateInvoiceTotal(res.invoiceId)
                        .pipe(
                          map((updateRes) =>
                            this.itemActions.loadItems({
                              query: currentQuery as any,
                            }),
                          ),
                        ),
                    ),
                  ),
                ),
                catchError((err) =>
                  of(
                    this.itemActions.setError({
                      error:
                        err.error?.message?.toString() ??
                        err.message?.toString() ??
                        err.toString(),
                    }),
                  ),
                ),
              ),
            ),
          ),
      ),
    ),
  );

  override deleteItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.itemActions.deleteItem),
      mergeMap((payload) =>
        this.service.getOne(payload.id).pipe(
          switchMap((getRes) =>
            this.service.delete(payload.id).pipe(
              switchMap((res) =>
                this.store
                  .select((s) => this.stateSelector(s).currentQuery)
                  .pipe(
                    take(1),
                    switchMap((res) =>
                      this.paymentService
                        .updateInvoiceTotal(getRes.invoiceId)
                        .pipe(
                          map((updateRes) =>
                            this.itemActions.loadItems({
                              query: query as any,
                            }),
                          ),
                        ),
                    ),
                  ),
              ),
              catchError((err) => {
                console.log(err);
                return of(
                  this.itemActions.setError({
                    error:
                      err.error?.message?.toString() ??
                      err.message?.toString() ??
                      err.toString(),
                  }),
                );
              }),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    protected override readonly actions$: Actions,
    protected override readonly store: Store<CoreAppState>,
    protected override readonly service: InvoiceItemService,
    private readonly orderServiceLinkageService: OrderServiceLinkageService,
    private readonly orderCardServiceLinkageService: OrderCardServiceLinkageService,
    private readonly paymentService: PaymentService,
  ) {
    super(actions$, store, service, invoiceItemActions, selectInvoiceItemState);
  }
}
