import { Component } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { PaymentService } from '@aa/angular/core';

@Component({
  selector: 'aa-payment-method-setup-popup',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './payment-method-setup-popup.component.html',
  styleUrls: ['./payment-method-setup-popup.component.scss'],
})
export class PaymentMethodSetupPopupComponent {
  constructor(private readonly paymentService: PaymentService) {}

  async setupPaymentMethod() {
    const { url } = await firstValueFrom(
      this.paymentService.getSetupPaymentMethodURL(),
    );

    if (url) location.href = url;
  }
}
