import { invoiceActions } from './invoice.actions';
import { CoreAppState } from '../core-app.state';
import {
  BaseResourceState,
  createBaseResourceReducer,
  initialResourceState,
} from '../create-base-resource-reducer';
import { InvoiceResourceTypeMappings } from '@aa/nest/resource';

export type InvoiceState = BaseResourceState<InvoiceResourceTypeMappings>;
export const initialInvoiceState: InvoiceState = initialResourceState;

export const invoiceReducer = createBaseResourceReducer<
  InvoiceResourceTypeMappings,
  InvoiceState
>(initialInvoiceState, invoiceActions);

export const selectInvoiceState = (state: CoreAppState) =>
  state.invoice ?? initialInvoiceState;
