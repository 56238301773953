import { CoreAppState } from '../state/core-app.state';
import { BaseResourceService } from './base-resource.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { InvoiceItem } from '@prisma/client';
import { InvoiceItemResourceTypeMappings } from '@aa/nest/resource';
import { invoiceItemCompositeKeyOrder } from '@aa/nest/resource/objects';

@Injectable()
export class InvoiceItemService extends BaseResourceService<InvoiceItemResourceTypeMappings> {
  constructor(
    protected override readonly http: HttpClient,
    protected override readonly store: Store<CoreAppState>,
  ) {
    super(http, store, 'invoice-items');
  }
}
