import { createAction, props } from '@ngrx/store';
import { createBaseResourceActions } from '../create-base-resource-actions';
import { RefundItemResourceTypeMappings } from '@aa/nest/resource';

// @aa:gen-ignore

const domainSlug = '[REFUND ITEM]';
export const refundItemActions = {
  ...createBaseResourceActions<RefundItemResourceTypeMappings>(domainSlug),

  finalize: createAction(
    `${domainSlug} Finalize`,
    props<{
      id: RefundItemResourceTypeMappings['resourcePrimaryKeyT'];
    }>(),
  ),
  cancel: createAction(
    `${domainSlug} Cancel`,
    props<{
      id: RefundItemResourceTypeMappings['resourcePrimaryKeyT'];
    }>(),
  ),
};
