import { CoreAppState } from '../state/core-app.state';
import { BaseResourceService } from './base-resource.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Invoice } from '@prisma/client';
import { InvoiceResourceTypeMappings } from '@aa/nest/resource';
import { invoiceCompositeKeyOrder } from '@aa/nest/resource/objects';

@Injectable()
export class InvoiceService extends BaseResourceService<InvoiceResourceTypeMappings> {
  constructor(
    protected override readonly http: HttpClient,
    protected override readonly store: Store<CoreAppState>,
  ) {
    super(http, store, 'invoices');
  }
}
