import { CoreAppState } from '../state/core-app.state';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InvoiceResourceTypeMappings } from '@aa/nest/resource';
import { Store } from '@ngrx/store';
import { Stripe } from 'stripe';

const httpOptions = {
  withCredentials: true,
};

@Injectable()
export class PaymentService {
  constructor(
    private readonly http: HttpClient,
    private store: Store<CoreAppState>,
  ) {}

  getSetupPaymentMethodURL() {
    return this.http.post<{ url: string }>(
      `${environment.paymentService}/get-setup-payment-method-url`,
      {},
      httpOptions,
    );
  }

  getPaymentMethodStatus() {
    return this.http.post<{ setup: boolean }>(
      `${environment.paymentService}/get-payment-method-status`,
      {},
      httpOptions,
    );
  }

  getPaymentMethods() {
    return this.http.post<Stripe.ApiListPromise<Stripe.PaymentMethod>>(
      `${environment.paymentService}/get-payment-methods`,
      {},
      httpOptions,
    );
  }

  getInvoiceAndPaymentDetailsForNewOrder(orderId: number) {
    return this.http.post<{
      invoice: InvoiceResourceTypeMappings['resourceWithRelationsT'];
      paymentIntent: { clientSecret: string };
      customerSession: { clientSecret: string };
    }>(
      `${environment.paymentService}/invoice-and-payment-details-for-new-order`,
      { orderId },
      httpOptions,
    );
  }

  adjustInvoicingForOrder(orderId: number) {
    return this.http.post<any>(
      `${environment.paymentService}/adjust-invoicing-for-order`,
      { orderId },
      httpOptions,
    );
  }

  updateInvoiceTotal(invoiceId: number) {
    return this.http.post<any>(
      `${environment.paymentService}/update-invoice-total/${invoiceId}`,
      // { invoiceId },

      {},
      httpOptions,
    );
  }

  finalizeInvoice(invoiceId: number) {
    return this.http.post<any>(
      `${environment.paymentService}/finalize-invoice`,
      { invoiceId },
      httpOptions,
    );
  }

  finalizeAllForOrder(orderId: number) {
    return this.http.post<any>(
      `${environment.paymentService}/finalize-all-for-order/${orderId}`,
      {},
      httpOptions,
    );
  }

  finalizeRefundItem(refundId: number) {
    return this.http.post<any>(
      `${environment.paymentService}/finalize-refund-item/${refundId}`,
      // { invoiceId },

      {},
      httpOptions,
    );
  }

  finalizeInvoicePayment(invoicePaymentId: number) {
    return this.http.post<any>(
      `${environment.paymentService}/finalize-invoice-payment/${invoicePaymentId}`,
      // { invoiceId },

      {},
      httpOptions,
    );
  }

  cancelInvoice(invoiceId: number) {
    return this.http.post<any>(
      `${environment.paymentService}/cancel-invoice/${invoiceId}`,
      // { invoiceId },

      {},
      httpOptions,
    );
  }

  cancelRefundItem(refundId: number) {
    return this.http.post<any>(
      `${environment.paymentService}/cancel-refund-item/${refundId}`,
      // { invoiceId },

      {},
      httpOptions,
    );
  }

  cancelInvoicePayment(invoicePaymentId: number) {
    return this.http.post<any>(
      `${environment.paymentService}/cancel-invoice-payment/${invoicePaymentId}`,
      // { invoiceId },

      {},
      httpOptions,
    );
  }

  cancelAllForOrder(orderId: number) {
    return this.http.post<any>(
      `${environment.paymentService}/cancel-all-for-order/${orderId}`,
      {},
      httpOptions,
    );
  }

  // getCheckoutSessionURL(orderId: number) {
  //   return this.http.post<{ url: string }>(
  //     `${environment.paymentService}/checkout-session-url`,
  //     { orderId },
  //     httpOptions,
  //   );
  // }

  getCustomerPortalURL() {
    return this.http.post<{ url: string }>(
      `${environment.paymentService}/generate-customer-portal-url`,
      {},
      httpOptions,
    );
  }

  associateCustomerFromSession(userId: number, sessionId: string) {
    return this.http.post(
      `${environment.paymentService}/associate-customer-from-session`,
      { userId, sessionId },
      httpOptions,
    );
  }
}
