export enum REFUND_ITEM_STATUS {
  STAFF_AUTO_DRAFT,
  STAFF_DRAFT,
  REFUNDED,
}

export const refundItemStatusLabels = {
  [REFUND_ITEM_STATUS.STAFF_AUTO_DRAFT]: 'Auto Draft',
  [REFUND_ITEM_STATUS.STAFF_DRAFT]: 'Draft',
  [REFUND_ITEM_STATUS.REFUNDED]: 'Refunded',
};

export const refundItemStatusColorMap = {
  [REFUND_ITEM_STATUS.STAFF_AUTO_DRAFT]: ['#FFF200', 'black'],
  [REFUND_ITEM_STATUS.STAFF_DRAFT]: ['#FFF200', 'black'],
  [REFUND_ITEM_STATUS.REFUNDED]: ['#00D624', 'white'],
};
