export enum INVOICE_STATUS {
  CUSTOMER_DRAFT,
  STAFF_DRAFT,
  STAFF_AUTO_DRAFT,
  PENDING,
  PAID,
}

export const invoiceStatusLabels = {
  [INVOICE_STATUS.CUSTOMER_DRAFT]: 'Draft',
  [INVOICE_STATUS.STAFF_DRAFT]: 'Draft',
  [INVOICE_STATUS.STAFF_AUTO_DRAFT]: 'Auto Draft',
  [INVOICE_STATUS.PENDING]: 'Pending',
  [INVOICE_STATUS.PAID]: 'Paid',
};

export const customerFacingInvoiceStatusLabels = {
  [INVOICE_STATUS.CUSTOMER_DRAFT]: 'Draft',
  [INVOICE_STATUS.STAFF_DRAFT]: 'Draft',
  [INVOICE_STATUS.STAFF_AUTO_DRAFT]: 'Draft',
  [INVOICE_STATUS.PENDING]: 'Pending',
  [INVOICE_STATUS.PAID]: 'Paid',
};

export const invoiceStatusColorMap = {
  [INVOICE_STATUS.CUSTOMER_DRAFT]: ['#FFF200', 'black'],
  [INVOICE_STATUS.STAFF_DRAFT]: ['#FFF200', 'black'],
  [INVOICE_STATUS.STAFF_AUTO_DRAFT]: ['#FFF200', 'black'],
  [INVOICE_STATUS.PENDING]: ['#D400FF', 'white'],
  [INVOICE_STATUS.PAID]: ['#00D624', 'white'],
};
