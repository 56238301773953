<div class="content">
  <div class="header">
    <h2>Invoices</h2>
    <div class="actions">
      <button mat-flat-button color="primary" (click)="createNewInvoiceDraft()">
        New Invoice
        <mat-icon iconPositionEnd>add</mat-icon>
      </button>
      <button
        mat-flat-button
        color="accent"
        (click)="adjustInvoicingForChanges()">
        Adjust for Order Changes
        <mat-icon iconPositionEnd>receipt_long</mat-icon>
      </button>
    </div>
  </div>

  <aa-invoice-history
    [orderId]="(orderId$ | async)!"
    [showAsCustomerView]="false"
    [hideTitle]="true"></aa-invoice-history>
</div>
