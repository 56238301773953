import {
  ConfirmDialogComponent,
  ConfirmDialogData,
  invoiceActions,
  orderActions,
  orderCardServiceLinkageActions,
  OrderFormModalComponent,
  OrderFormModalData,
  orderServiceLinkageActions,
  orderSourceActions,
  orderStatusActions,
  selectOrderCardState,
  selectOrderSourceState,
  selectOrderState,
  selectOrderStatusState,
} from '@aa/angular/core';
import {
  OrderResourceTypeMappings,
  OrderSourceResourceTypeMappings,
  OrderStatusResourceTypeMappings,
} from '@aa/nest/resource';
import { INVOICE_STATUS, REFUND_ITEM_STATUS } from '@aa/nest/resource/objects';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  of,
} from 'rxjs';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { computeTextColorForBackground, formatDateTime } from '@aa/ts/common';
import {
  DataTableComponent,
  DataTableConfig,
} from '../../../../../core/src/lib/components/data-table/data-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBadgeModule } from '@angular/material/badge';
import { StaffAppState } from '../../state/index.reducers';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OrderCardsTabComponent } from '../../components/order-cards-tab/order-cards-tab.component';
import { OrderInvoicesTabComponent } from '../../components/order-invoices-tab/order-invoices-tab.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MultipleRequestedServicesFormFieldValue } from '../../components/requested-services-form-field/requested-services-form-field.component';

@Component({
  selector: 'aa-order-detail-view',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    FormlyModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatBadgeModule,
    DataTableComponent,

    OrderCardsTabComponent,
    OrderInvoicesTabComponent,
  ],
  templateUrl: './order-detail-view.component.html',
  styleUrl: './order-detail-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDetailViewComponent {
  summaryTableConfig: DataTableConfig = {
    data$: of([]),
    columns: [
      {
        key: 'attribute',
        label: 'Attribute',
        accessor: (row) => `${row.attribute}: `,
        getStyle: () => ({
          'font-weight': '700',
          'min-width': 'fit-content',
          'text-wrap': 'nowrap',
        }),
        getHeaderStyle: () => ({ minWidth: 'fit-content' }),
      },
      {
        key: 'value',
        label: 'Value',
        getStyle: () => ({ width: '100%' }),
        getHeaderStyle: () => ({ width: '100%' }),
      },
    ],
    numItems$: of(0),
    hidePagination: true,
    hideTableHeaders: true,
    hideShadow: true,
  };

  orderId$: Observable<number>;
  order$: Observable<
    OrderResourceTypeMappings['resourceWithRelationsT'] | undefined | null
  >;
  orderStatuses$: Observable<
    OrderStatusResourceTypeMappings['resourceWithRelationsT'][]
  >;
  orderSources$: Observable<
    OrderSourceResourceTypeMappings['resourceWithRelationsT'][]
  >;

  currentTabIndex$ = new BehaviorSubject(0);

  constructor(
    private readonly store: Store<StaffAppState>,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly dialog: MatDialog,
  ) {
    this.order$ = this.store
      .select((s) => selectOrderState(s).current)
      .pipe(takeUntilDestroyed());
    this.orderStatuses$ = this.store
      .select((s) => selectOrderStatusState(s).items)
      .pipe(takeUntilDestroyed());
    this.orderSources$ = this.store
      .select((s) => selectOrderSourceState(s).items)
      .pipe(takeUntilDestroyed());

    this.orderId$ = this.route.paramMap.pipe(
      map((paramMap) => parseInt(paramMap.get('id')!)),
      takeUntilDestroyed(),
    );

    this.store.dispatch(orderStatusActions.loadItems({}));
    this.store.dispatch(orderSourceActions.loadItems({}));

    this.orderId$.subscribe((orderId) => {
      this.store.dispatch(
        orderActions.loadItem({
          id: orderId,
          include: {
            customerProfile: true,
            orderSource: true,
            orderStatus: true,
            orderServiceLinkages: {
              include: {
                offeredService: true,
              },
            },

            _count: {
              select: {
                orderCards: {
                  where: {
                    orderCardServiceLinkages: {
                      some: {
                        isDraft: true,
                      },
                    },
                  },
                },
                orderServiceLinkages: {
                  where: {
                    isDraft: true,
                  },
                },
                invoices: {
                  where: {
                    OR: [
                      {
                        status: {
                          not: INVOICE_STATUS.PAID,
                        },
                      },
                      {
                        refundItems: {
                          some: {
                            status: { not: REFUND_ITEM_STATUS.REFUNDED },
                          },
                        },
                      },
                    ],
                  },
                },
              },
            },
          },
        }),
      );
    });

    this.summaryTableConfig = {
      ...this.summaryTableConfig,
      data$: this.order$.pipe(
        map((order) => [
          {
            attribute: 'Customer',
            value: `${order?.customerProfile?.firstName ?? ''} ${
              order?.customerProfile?.lastName ?? ''
            }`,
          },
          // {
          //   attribute: 'Requested Services',
          //   value: (order?.orderServiceLinkages ?? [])
          //     .map((li) => li.offeredService?.name)
          //     .join(', '),
          // },
          {
            attribute: 'Notes',
            value: order?.notes,
          },
          {
            attribute: 'Additional Services',
            value: order?.orderServiceLinkages
              ?.map((li) => li.offeredService?.name ?? '')
              .join(', '),
          },
          {
            attribute: 'Customer Facing Notes',
            value: order?.customerFacingNotes,
          },
        ]),
      ),
    };
  }

  async openEditForm() {
    const order = await firstValueFrom(this.order$);
    if (!order) return;
    const data: OrderFormModalData = {
      mode: 'update',
      model: {
        ...order,
        requestedServices: {
          draftValues: order.orderServiceLinkages
            ?.filter((li) => li.isDraft)
            ?.map((li) => li.offeredServiceId),
          values: order.orderServiceLinkages
            ?.filter((li) => !li.isDraft)
            ?.map((li) => li.offeredServiceId),
        } as MultipleRequestedServicesFormFieldValue,
      },
    } as any;
    this.dialog.open(OrderFormModalComponent, {
      data,
      minWidth: '50vw',
      maxWidth: 'calc(100vw - 3rem)',
      maxHeight: 'calc(100vh - 3rem)',
      autoFocus: false,
    });
  }

  async promptDeleteItem() {
    const order = await firstValueFrom(this.order$);
    const data: ConfirmDialogData = {
      title: 'Are you sure you want to delete this item?',
      subtitle: 'This action cannot be undone.',
    };
    const confirmation = await lastValueFrom(
      this.dialog
        .open(ConfirmDialogComponent, {
          autoFocus: false,
          data,
        })
        .afterClosed(),
    );

    if (confirmation && order) {
      // TODO: make this also work with non 'id' primary keys
      this.store.dispatch(orderActions.deleteItem({ id: order.id }));
      this.router.navigate(['/']);
    }
  }

  async updateOrderStatus(newStatusId: number) {
    const orderId = await firstValueFrom(this.orderId$);
    if (orderId) {
      this.store.dispatch(
        orderActions.updateItem({
          id: orderId,
          data: {
            orderStatusId: newStatusId,
          },
        }),
      );
    }
  }

  async updateOrderSource(newSourceId: number) {
    const orderId = await firstValueFrom(this.orderId$);
    if (orderId) {
      this.store.dispatch(
        orderActions.updateItem({
          id: orderId,
          data: {
            orderSourceId: newSourceId,
          },
        }),
      );
    }
  }

  async toggleShowChangesToCustomer() {
    const orderId = await firstValueFrom(this.orderId$);
    const showChangesToCustomer = (await firstValueFrom(this.order$))
      ?.showChangesToCustomer;
    this.store.dispatch(
      orderActions.updateItem({
        id: orderId,
        data: {
          showChangesToCustomer: !showChangesToCustomer,
        },
      }),
    );
  }

  async sendChangeEmail() {
    const data: ConfirmDialogData = {
      title:
        'Are you sure you want to send these drafted changes to the customer?',
    };
    const confirmation = await lastValueFrom(
      this.dialog
        .open(ConfirmDialogComponent, {
          autoFocus: false,
          data,
        })
        .afterClosed(),
    );

    if (!confirmation) return;
    const id = await firstValueFrom(this.orderId$);
    this.store.dispatch(
      orderActions.sendChangeEmail({
        id,
      }),
    );
  }

  async clearDraftedChanges() {
    const data: ConfirmDialogData = {
      title: 'Are you sure you want to clear these drafted changes?',
      subtitle:
        'This will also cancel all auto drafted invoice items and pending refunds.',
    };
    const confirmation = await lastValueFrom(
      this.dialog
        .open(ConfirmDialogComponent, {
          autoFocus: false,
          data,
        })
        .afterClosed(),
    );

    if (!confirmation) return;
    const orderId = await firstValueFrom(this.orderId$);
    const cards = await firstValueFrom(
      this.store.select((s) => selectOrderCardState(s).items),
    );

    this.store.dispatch(invoiceActions.cancelAllForOrder({ id: orderId }));

    for (const card of cards) {
      const hasDraftedChanges = !!card.orderCardServiceLinkages?.find(
        (li) => li.isDraft,
      );

      if (!hasDraftedChanges) continue;

      for (const serviceLinkage of card.orderCardServiceLinkages ?? []) {
        if (serviceLinkage.isDraft) {
          this.store.dispatch(
            orderCardServiceLinkageActions.deleteItem({
              id: serviceLinkage.id,
            }),
          );
        }
      }
    }

    this.store.dispatch(
      orderActions.updateItem({
        id: orderId,
        data: {
          changesApproved: null as any,
        },
      }),
    );
  }

  async finalizeDraftedChanges() {
    const data: ConfirmDialogData = {
      title: 'Are you sure you want to finalize these drafted changes?',
      subtitle:
        'This will also finalize all auto drafted invoice items and pending refunds.',
    };
    const confirmation = await lastValueFrom(
      this.dialog
        .open(ConfirmDialogComponent, {
          autoFocus: false,
          data,
        })
        .afterClosed(),
    );

    if (!confirmation) return;
    const orderId = await firstValueFrom(this.orderId$);
    const order = await firstValueFrom(this.order$);
    const cards = await firstValueFrom(
      this.store.select((s) => selectOrderCardState(s).items),
    );

    const orderHasDraftedChanges = !!order!.orderServiceLinkages?.find(
      (li) => li.isDraft,
    );

    let deletedIds: number[] = [];
    if (orderHasDraftedChanges) {
      for (const serviceLinkage of order!.orderServiceLinkages ?? []) {
        if (serviceLinkage.isDraft) {
          // check if we already have service
          const existingLinkage = order!.orderServiceLinkages?.find(
            (existing) =>
              existing.offeredServiceId == serviceLinkage.offeredServiceId &&
              !existing.isDraft,
          );

          if (existingLinkage) {
            this.store.dispatch(
              orderServiceLinkageActions.updateItem({
                id: serviceLinkage.id,
                data: {
                  refundItemId: existingLinkage.refundItemId ?? undefined,
                  invoiceItemId: existingLinkage.invoiceItemId ?? undefined,
                  isDraft: false,
                },
              }),
            );

            if (!deletedIds.includes(existingLinkage.id)) {
              this.store.dispatch(
                orderServiceLinkageActions.deleteItem({
                  id: existingLinkage.id,
                }),
              );

              deletedIds = [...deletedIds, existingLinkage.id];
            }
          } else {
            this.store.dispatch(
              orderServiceLinkageActions.updateItem({
                id: serviceLinkage.id,
                data: {
                  isDraft: false,
                },
              }),
            );
          }
        } else {
          if (
            !deletedIds.includes(serviceLinkage.id) &&
            !order!.orderServiceLinkages?.find(
              (existing) =>
                existing.offeredServiceId == serviceLinkage.id &&
                existing.isDraft,
            )
          ) {
            this.store.dispatch(
              orderCardServiceLinkageActions.deleteItem({
                id: serviceLinkage.id,
              }),
            );
            deletedIds = [...deletedIds, serviceLinkage.id];
          }
        }
      }
    }

    deletedIds = [];
    for (const card of cards) {
      const hasDraftedChanges = !!card.orderCardServiceLinkages?.find(
        (li) => li.isDraft,
      );

      if (!hasDraftedChanges) continue;

      for (const serviceLinkage of card.orderCardServiceLinkages ?? []) {
        if (serviceLinkage.isDraft) {
          // check if we already have service
          const existingLinkage = card.orderCardServiceLinkages?.find(
            (existing) =>
              existing.offeredServiceId == serviceLinkage.offeredServiceId &&
              !existing.isDraft,
          );

          if (existingLinkage) {
            this.store.dispatch(
              orderCardServiceLinkageActions.updateItem({
                id: serviceLinkage.id,
                data: {
                  refundItemId: existingLinkage.refundItemId ?? undefined,
                  invoiceItemId: existingLinkage.invoiceItemId ?? undefined,
                  isDraft: false,
                },
              }),
            );

            if (!deletedIds.includes(existingLinkage.id)) {
              this.store.dispatch(
                orderCardServiceLinkageActions.deleteItem({
                  id: existingLinkage.id,
                }),
              );

              deletedIds = [...deletedIds, existingLinkage.id];
            }
          } else {
            this.store.dispatch(
              orderCardServiceLinkageActions.updateItem({
                id: serviceLinkage.id,
                data: {
                  isDraft: false,
                },
              }),
            );
          }
        } else {
          if (
            !deletedIds.includes(serviceLinkage.id) &&
            !card.orderCardServiceLinkages?.find(
              (existing) =>
                existing.offeredServiceId == serviceLinkage.id &&
                existing.isDraft,
            )
          ) {
            this.store.dispatch(
              orderCardServiceLinkageActions.deleteItem({
                id: serviceLinkage.id,
              }),
            );
            deletedIds = [...deletedIds, serviceLinkage.id];
          }
        }
      }
    }

    this.store.dispatch(invoiceActions.finalizeAllForOrder({ id: orderId }));

    this.store.dispatch(
      orderActions.updateItem({
        id: orderId,
        data: {
          hasPendingServiceChanges: false,
          changesApproved: null as any,
        },
      }),
    );
  }

  selectedIndexChanged(index: number) {
    this.currentTabIndex$.next(index);
  }

  getTextColorForBackground = computeTextColorForBackground;

  formatDateTime = formatDateTime;
}
