import { orderCardServiceLinkageActions } from './order-card-service-linkage.actions';
import { CoreAppState } from '../core-app.state';
import {
  BaseResourceState,
  createBaseResourceReducer,
  initialResourceState,
} from '../create-base-resource-reducer';
import { OrderCardServiceLinkageResourceTypeMappings } from '@aa/nest/resource';

export type OrderCardServiceLinkageState =
  BaseResourceState<OrderCardServiceLinkageResourceTypeMappings>;
export const initialOrderCardServiceLinkageState: OrderCardServiceLinkageState =
  initialResourceState;

export const orderCardServiceLinkageReducer = createBaseResourceReducer<
  OrderCardServiceLinkageResourceTypeMappings,
  OrderCardServiceLinkageState
>(initialOrderCardServiceLinkageState, orderCardServiceLinkageActions);

export const selectOrderCardServiceLinkageState = (state: CoreAppState) =>
  state.orderCardServiceLinkage ?? initialOrderCardServiceLinkageState;
